<template>
  <div>
    <div class="h-16 flex items-center">
      <h1
        class="text-base lg:text-xl mr-auto"
        style="font-family: 'poppins-SemiBold', 'sans-serif'"
      >
        Publipostage
      </h1>
      <BaseModal ref="addPublipostage" class="mr-4" @validate="callValidate">
        <template v-slot:button-content>
          <img
            src="@/assets/ico/plus.svg"
            alt="Ajouter un publipostage."
            class="w-7 max-w-lg"
          />
          <p class="ml-4">Créer un publipostage</p>
        </template>
        <template v-slot:title>
          <h3
            class="text-xl"
            style="font-family: 'Poppins-SemiBold', 'sans-serif'"
          >
            Création d'un publipostage
          </h3>
        </template>
        <template v-slot:content>
          <publipostage-form
            ref="validate"
            :publipostage-filter="publipostageFilter"
            @closeCreateModal="closeCreateModal"
          />
        </template>
      </BaseModal>
      <slot name="header-buttons" />
    </div>
    <div class="my-11">
      <filter-bar
        v-model="publipostageFilter"
        storage-key="publipostage_filters"
        :config="filterConfig"
      />
      <div
        class="
          flex flex-wrap
          border border-gray-200
          gap-4
          my-8
          rounded-2xl
          p-3.5
        "
      >
        <div class="hidden lg:flex flex-wrap w-full justify-end">
          <button
            class="border border-gray-200 shadow-md p-3 rounded-lg"
            :class="
              isSelected.length === 0 ? 'opacity-50 cursor-not-allowed' : ''
            "
            @click="exportCsv()"
          >
            <div class="flex items-center justify-center text-secondary">
              <p class="mr-2">Exporter</p>
              <img
                src="@/assets/ico/exporter.svg"
                alt="Exporter"
                class="h-6 w-6"
              />
            </div>
          </button>
        </div>
        <div
          class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full gap-4"
        >
          <publipostage-cards
            v-for="publipostage in publipostages"
            :key="publipostage.uuid"
            :is-selected="isSelected"
            :publipostage="publipostage"
            :publipostage-filter="publipostageFilter"
          />
          <div
            v-show="publipostages && publipostages.length === 0"
            class="col-span-full flex justify-center items-center py-5"
          >
            <p
              class="
                px-8
                py-3
                rounded-full
                bg-primary
                text-white
                cursor-pointer
                hover:shadow
              "
              @click="clearFilter"
            >
              Aucun publipostage n'est disponible, réinitialiser les filtres ?
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FilterBar from "@/components/filters/FilterBar";
import ClickOutside from "vue-click-outside";
import PublipostageFilter from "@/model/PublipostageFilter";
import PublipostageExportModel from "@/model/Publipostage/PublipostageExportModel";
import PublipostageCards from "@/components/utils/Publipostage/cards";
import BaseModal from "@/components/modals/baseModal";
import PublipostageForm from "@/components/forms/publipostage/PublipostageForm";
import { FILTER_TYPES } from "@/components/filters/filterTypes";

export default {
  name: "PublipostagesAdmin",
  components: {
    FilterBar,
    PublipostageForm,
    PublipostageCards,
    BaseModal,
  },
  directives: {
    ClickOutside,
  },
  data() {
    return {
      filterConfig: [
        {
          fields: ["_searchMarques"],
          type: FILTER_TYPES.MARQUE_TYPE,
          props: {
            multiple: true,
          },
        },
        {
          fields: ["_searchStatus"],
          type: FILTER_TYPES.STATUS_TYPE,
        },
        {
          fields: ["_searchMonth", "_searchYear"],
          type: FILTER_TYPES.MONTH_YEAR_TYPE,
        },
      ],
      date: {},
      isSelected: [],
      publipostageFilter: new PublipostageFilter(),
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.users.currentUser;
    },
    publipostages() {
      return this.$store.state.publipostages.publipostages;
    },
  },
  watch: {
    publipostageFilter: {
      handler() {
        this.search();
      },
      deep: true,
    },
  },
  mounted() {
    this.search();
  },
  methods: {
    changeDate(date) {
      this.publipostageFilter._searchDate = date;
    },
    search() {
      if (
        !this.publipostageFilter.getSearchMonth().value ||
        !this.publipostageFilter.getSearchYear().value
      ) {
        return;
      }
      const { dispatch } = this.$store;

      dispatch({
        type: "publipostages/getPublipostages",
        publipostageFilter: this.publipostageFilter,
      });
    },
    clearFilter() {
      this.publipostageFilter = new PublipostageFilter();
    },
    callValidate() {
      this.$refs.validate.handleCreateSubmit();
    },
    exportCsv() {
      if (this.isSelected.length > 0) {
        const publipostageExportModel = this.isSelected.map(
          (adresseSelected) => {
            return new PublipostageExportModel(adresseSelected);
          }
        );

        const { dispatch } = this.$store;

        dispatch({
          type: "publipostages/exportPublipostages",
          publipostageExportModel: publipostageExportModel,
        });

        this.isSelected = [];
      }
    },
    closeCreateModal() {
      this.$refs.addPublipostage.closeModal();
    },
  },
};
</script>

<style lang="scss">
.month-picker-input-container {
  width: 100%;
}

.month-picker__container {
  background-color: white;
}
</style>
