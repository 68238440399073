<template>
  <div id="login-page">
    <div class="flex items-center justify-center fixed top-0 left-0">
      <router-link to="/login">
        <img
          src="@/assets/img/logo-noir.svg"
          alt="Logo du publipostage"
          class="py-4 w-64"
        />
      </router-link>
    </div>
    <div
      class="flex flex-wrap justify-center items-center min-h-screen min-w-full"
    >
      <div
        class="
          w-11/12
          lg:w-6/12
          xl:7/12
          px-10
          max-w-lg
          rounded-3xl
          shadow-xl
          p-4
        "
      >
        <div class="text-center mb-10">
          <h1 class="text-3xl">Connexion</h1>
        </div>
        <form @submit.prevent="handleSubmit">
          <div class="flex -mx-3">
            <div class="w-full px-3 mb-8">
              <label for="email" class="text-md px-1 mb-2">Email</label>
              <input
                id="email"
                v-model="username"
                name="username"
                type="email"
                class="
                  w-full
                  mt-1.5
                  pl-6
                  pr-3
                  py-4
                  text-base
                  rounded-full
                  border border-gray-200
                  outline-none
                  focus:border-primary
                  caret-primary
                "
                required
              />
            </div>
          </div>
          <div class="flex -mx-3">
            <div class="w-full px-3 mb-2">
              <label for="password" class="text-md px-1 mb-2"
                >Mot de passe</label
              >
              <input
                id="password"
                v-model="password"
                type="password"
                name="password"
                class="
                  w-full
                  pl-6
                  mt-1.5
                  pr-3
                  py-4
                  text-base
                  rounded-full
                  border border-gray-200
                  outline-none
                  focus:border-primary
                "
                required
              />
            </div>
          </div>
          <div class="flex -mx-3 mb-12">
            <div class="w-full text-right px-4">
              <router-link
                to="/password/request"
                class="text-gray-400 flex justify-end"
              >
                <img
                  src="@/assets/ico/cadena.svg"
                  alt="icone de cadena mdp oublié"
                  class="w-3 mr-1"
                />
                Mot de passe oublié
              </router-link>
            </div>
          </div>
          <div class="flex flex-wrap mb-8">
            <p
              v-if="alert.type === 'alert-success'"
              class="w-full text-green-400 flex justify-center"
            >
              {{ alert.message }}
            </p>
            <p
              v-if="alert.type === 'alert-danger'"
              class="w-full text-red-400 flex"
            >
              <img
                src="@/assets/ico/warning.svg"
                alt="icone d'exclamation"
                class="w-5 mr-1"
              />{{ alert.message }}
            </p>
          </div>
          <div class="flex -mx-3">
            <div class="w-full px-3 mb-5">
              <button
                id="submitButton"
                class="block w-full text-white rounded-full px-3 py-3"
              >
                Se connecter
              </button>
            </div>
          </div>
        </form>
      </div>
      <div class="w-full hidden lg:flex lg:w-4/12 p-3 justify-center">
        <img
          src="@/assets/img/login/connexion.svg"
          alt="Image de boite au lettre d'illustration."
          class="w-full max-w-lg"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      submitted: false,
    };
  },
  computed: {
    loggingIn() {
      return this.$store.state.authentication.status.loggingIn;
    },
    alert() {
      return this.$store.state.alert;
    },
  },
  methods: {
    handleSubmit() {
      this.submitted = true;
      const { username, password } = this;
      const { dispatch } = this.$store;
      if (username && password) {
        dispatch("authentication/login", { username, password });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#submitButton {
  background: transparent linear-gradient(268deg, #f5264c 0%, #e31bb1 100%) 0%
    0% no-repeat padding-box;

  &:hover {
    background: transparent linear-gradient(268deg, #e31bb1 0%, #f5264c 100%) 0%
      0% no-repeat padding-box;
  }
}

button:focus {
  outline: none;
}

input {
  caret-color: #ff3693;
}

h1 {
  font-family: "Poppins-bold", sans-serif;
}
</style>
