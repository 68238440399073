<template>
  <div
    class="
      flex flex-col
      w-full
      h-full
      border border-gray-200
      rounded-xl
      group
      hover:shadow-lg
    "
  >
    <div class="w-full flex justify-between items-center px-5 pt-5 pb-4">
      <div>
        <AgenceIcon class="h-16 w-auto" :style="{ color: brand.couleur }" />
      </div>
      <div>
        <h3
          class="text-left text-base mx-4"
          style="font-family: 'Poppins-Medium', 'sans-serif'"
        >
          {{ agence.nom }}
        </h3>
      </div>
      <div class="rounded-xl shadow-lg">
        <PublipostageIcon
          class="h-12 w-auto"
          :style="{ color: brand.couleur }"
        />
      </div>
    </div>
    <div class="w-full flex justify-center items-center p-4">
      <div class="w-full flex flex-wrap justify-center">
        <div class="w-full text-gray-400 text-center">
          <font-awesome-icon icon="user" size="lg" />
        </div>
        <h3 class="w-full font-semi-bold text-center mt-2">
          {{ agence.responsableNom }}
        </h3>
      </div>
      <div class="border-r border-gray-200 h-12 mx-10" />
      <div class="w-full flex flex-wrap justify-center">
        <div class="w-full text-gray-400 text-center">
          <font-awesome-icon icon="phone" size="lg" />
        </div>
        <h3 class="w-full font-semi-bold text-center mt-2">
          {{ agence.responsableTelephone }}
        </h3>
      </div>
    </div>
    <div
      class="
        w-full
        flex flex-wrap
        justify-center
        items-center
        w-full
        border-t border-gray-200
        p-4
      "
    >
      <div class="text-gray-400">
        <font-awesome-icon icon="map-marker-alt" size="lg" />
      </div>
      <div class="text-sm text-gray-800 ml-3 text-left">
        <p>{{ agence.adresse }}</p>
        <p>{{ agence.codePostal }} {{ agence.ville }}</p>
      </div>
    </div>
    <div
      class="
        mt-auto
        w-full
        flex
        h-2
        rounded-b-lg
        opacity-0
        group-hover:opacity-100
        transition-opacity
      "
      :style="{ backgroundColor: brand.couleur }"
    />
  </div>
</template>

<script>
import AgenceIcon from "@/components/icons/AgenceIcon";
import PublipostageIcon from "@/components/icons/PublipostageIcon";

export default {
  name: "AgenceCard",
  components: { AgenceIcon, PublipostageIcon },
  props: {
    agence: {
      type: Object,
      required: true,
    },
    brand: {
      type: Object,
      required: true,
    },
    noHover: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cardClasses() {
      if (this.noHover) {
        return "";
      }

      return "hover:shadow-lg";
    },
  },
};
</script>

<style scoped></style>
