export default class PublipostageModel {
  constructor(nom, marque, dateMiseEnLigne, dateLimite) {
    const currentDate = new Date(new Date().setHours(12, 0));

    this.nom = nom;
    this.marque =
      marque === undefined
        ? { key: "technitoit", value: "Technitoit" }
        : marque;
    this.dateMiseEnLigne =
      dateMiseEnLigne === undefined
        ? currentDate.toISOString()
        : dateMiseEnLigne;
    this.dateLimite =
      dateLimite === undefined
        ? new Date(
            currentDate.setMonth(currentDate.getMonth() + 1)
          ).toISOString()
        : dateLimite;
  }

  getNom() {
    return this.nom;
  }

  getMarque() {
    return this.marque;
  }

  getDateMiseEnLigne() {
    return this.dateMiseEnLigne;
  }

  getDateLimite() {
    return this.dateLimite;
  }

  getStatus() {
    return this.status;
  }
}
