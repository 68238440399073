import { publipostageService } from "@/services";
import PublipostageDto from "@/dto/Publipostage";

export const publipostages = {
  namespaced: true,
  state: {
    publipostages: {},
  },
  actions: {
    async getPublipostages({ commit, dispatch }, { publipostageFilter }) {
      await dispatch({ type: "marques/getMarques" }, { root: true });
      let page = 1;
      let arrayPublipostages = [];
      let total = 0;

      do {
        await publipostageService
          .getPublipostages(publipostageFilter, page)
          .then((publipostages) => {
            total = publipostages.pages;
            page++;
            arrayPublipostages.push(...publipostages.results);
          });
      } while (total >= page);

      commit("setPublipostages", arrayPublipostages);
      dispatch("loading/stopLoad", "", { root: true });
    },
    async createPublipostage(
      { rootState, dispatch },
      { publipostageModel, publipostageFilter }
    ) {
      dispatch("loading/startLoad", "", { root: true });
      await dispatch({ type: "marques/getMarques" }, { root: true });

      const marque = await rootState.marques.marques.filter((marque) => {
        return marque.code === publipostageModel.getMarque().key;
      });

      const publipostageDto = new PublipostageDto(
        publipostageModel.getNom(),
        Array.from(marque)[0].uuid,
        publipostageModel.getDateMiseEnLigne(),
        publipostageModel.getDateLimite()
      );

      if (publipostageDto.getMarque().length < 1) {
        dispatch("loading/stopLoad", "", { root: true });
        return;
      }

      await publipostageService
        .createPublipostage(publipostageDto)
        .finally(() => {
          dispatch({
            type: "getPublipostages",
            publipostageFilter: publipostageFilter,
          });
        });
    },
    async updatePublipostage(
      { rootState, dispatch },
      { publipostageModel, publipostageUuid, publipostageFilter }
    ) {
      dispatch("loading/startLoad", "", { root: true });
      await dispatch({ type: "marques/getMarques" }, { root: true });

      const marque = await rootState.marques.marques.filter((marque) => {
        return marque.code === publipostageModel.getMarque().key;
      });

      const publipostageDto = new PublipostageDto(
        publipostageModel.getNom(),
        Array.from(marque)[0].uuid,
        publipostageModel.getDateMiseEnLigne(),
        publipostageModel.getDateLimite()
      );

      if (publipostageDto.getMarque().length < 1) {
        dispatch("loading/stopLoad", "", { root: true });
        return;
      }

      await publipostageService
        .updatePublipostage(publipostageDto, publipostageUuid)
        .finally(() => {
          dispatch({
            type: "getPublipostages",
            publipostageFilter: publipostageFilter,
          });
        });
    },
    finishPublipostage({ dispatch }, { publipostageUuid, publipostageFilter }) {
      dispatch("loading/startLoad", "", { root: true });
      publipostageService
        .finishPublipostage(publipostageUuid)
        .finally(() => {
          dispatch({
            type: "getPublipostages",
            publipostageFilter: publipostageFilter,
          });
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    sendedPublipostage({ dispatch }, { publipostageUuid, publipostageFilter }) {
      dispatch("loading/startLoad", "", { root: true });
      publipostageService
        .sendedPublipostage(publipostageUuid)
        .finally(() => {
          dispatch({
            type: "getPublipostages",
            publipostageFilter: publipostageFilter,
          });
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    deletePublipostage({ dispatch }, { publipostageUuid, publipostageFilter }) {
      dispatch("loading/startLoad", "", { root: true });
      publipostageService
        .deletePublipostage(publipostageUuid)
        .finally(() => {
          dispatch({
            type: "getPublipostages",
            publipostageFilter: publipostageFilter,
          });
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    updateParticipate(
      { dispatch },
      { publipostageUuid, participates, publipostageFilter }
    ) {
      dispatch("loading/startLoad", "", { root: true });
      publipostageService
        .patchParticipate(publipostageUuid, participates)
        .finally(() => {
          dispatch({
            type: "getPublipostages",
            publipostageFilter: publipostageFilter,
          });
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    exportPublipostages({ dispatch }, { publipostageExportModel }) {
      dispatch("loading/startLoad", "", { root: true });

      publipostageService
        .exportPublipostage(publipostageExportModel)
        .finally(() => {
          dispatch("loading/stopLoad", "", { root: true });
        });
    },
    relanceAgence(
      { dispatch },
      { publipostageUuid, agences, publipostageFilter }
    ) {
      dispatch("loading/startLoad", "", { root: true });
      publipostageService
        .relanceAgence(publipostageUuid, agences)
        .finally(() => {
          dispatch({
            type: "getPublipostages",
            publipostageFilter: publipostageFilter,
          });
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
  },
  mutations: {
    setPublipostages(state, publipostages) {
      state.publipostages = publipostages;
    },
  },
};
