<template>
  <publipostages-admin v-if="checkAdmin">
    <template v-slot:header-buttons>
      <slot name="header-buttons" />
    </template>
  </publipostages-admin>
  <publipostages-user v-else>
    <template v-slot:header-buttons>
      <slot name="header-buttons" />
    </template>
  </publipostages-user>
</template>

<script>
import { isAdmin } from "@/helpers/user";
import PublipostagesAdmin from "@/views/publipostage/PublipostagesAdmin";
import PublipostagesUser from "@/views/publipostage/PublipostagesUser";

export default {
  name: "Publipostages",
  components: {
    PublipostagesAdmin,
    PublipostagesUser,
  },
  computed: {
    checkAdmin() {
      return isAdmin();
    },
  },
};
</script>
