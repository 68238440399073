<template>
  <div class="border border-gray-200 rounded-2xl p-5">
    <h2
      class="font-medium mb-8"
      style="font-family: 'Poppins-Medium', 'sans-serif'; color: #b4b3b3"
    >
      Publipostages
    </h2>
    <doughnut-chart
      v-if="isLoaded"
      :chart-data="chartData"
      :options="options"
      :plugins="plugins"
      class="chart-container"
    />
  </div>
</template>

<script>
import { reportingService } from "@/services";
import DoughnutChart from "@/components/reporting/indicators/DoughnutChart";

export default {
  name: "PublipostagesPercentage",
  components: {
    DoughnutChart,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chartData: {
        datasets: [
          {
            backgroundColor: ["#f32459", "#e6e6e6"],
            borderColor: ["#f32459", "#e6e6e6"],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 85,
        legend: {
          position: "right",
          labels: {
            usePointStyle: true,
            padding: 30,
            xPadding: 15,
            fontStyle: "bold",
            fontColor: "#000",
            fontSize: 13,
          },
        },
        tooltips: {
          enabled: false,
        },
      },
      plugins: [
        {
          afterDraw: (chart) => {
            const height = chart.chart.height;
            const ctx = chart.chart.ctx;
            const legendLeft = chart.chart.legend.left;
            ctx.save();
            ctx.font = "bold 36px Arial";
            ctx.fillStyle = "black";
            ctx.textAlign = "center";
            ctx.fillText(
              chart.chart.data.datasets[0].data[0] + " %",
              legendLeft / 2,
              height / 2
            );
            ctx.restore();
          },
        },
      ],
      isLoaded: false,
    };
  },
  watch: {
    filters() {
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      const chartData = await reportingService.getPublipostagesPercentage(
        this.filters
      );
      const chartDataCopy = { ...this.chartData };
      chartDataCopy.labels = chartData.labels;
      chartDataCopy.datasets[0].data = chartData.data;
      this.chartData = chartDataCopy;
      this.isLoaded = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.chart-container {
  // Take 100% of his parent - size of title - size of gap
  height: calc(100% - 1.5em - 2rem);
}
</style>
