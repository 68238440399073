export function catchErrors(errors, data) {
  if (errors.type === "validation_error") {
    for (const error of errors.errors) {
      data[error.propertyPath] = error.message;
    }
  }

  if (errors.type === "object_already_exist") {
    data.commonError = errors.title;
  }
}
