<template>
  <div>
    <div class="mx-auto px-4 sm:px-6 md:px-8 mt-8">
      <!-- Replace with your content -->
      <div class="py-4">
        <div class="border-4 border-dashed rounded-lg h-96">
          <div class="mx-auto px-4 sm:px-6 md:px-8 mt-5">
            <h1 class="text-2xl font-semibold text-gray-900">Blacklist</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Blacklist",
};
</script>
