<template>
  <router-link
    :to="link"
    class="nav-item"
    :class="linkClass"
    @mouseover.native="setHoverImg"
    @mouseout.native="setOutImg"
  >
    <img :src="src" alt="logo menu" class="w-6 fill-current text-white" />
    <span class="hidden lg:inline ml-6">
      <slot />
    </span>
  </router-link>
</template>

<script>
export default {
  name: "NavItem",
  props: {
    logo: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
    menuName: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    ico: "",
    icoActif: "",
    src: "",
    linkClass: "",
  }),
  computed: {
    getDefaultImg() {
      return require(`@/assets/ico/menu/` + this.logo + `.svg`);
    },
    getHoverImg() {
      return require(`@/assets/ico/menu/` + this.logo + `-actif.svg`);
    },
  },
  watch: {
    $route() {
      this.init();
    },
  },
  created() {
    this.init();
  },
  methods: {
    setHoverImg() {
      if (
        this.$router.currentRoute.meta.menu &&
        this.$router.currentRoute.meta.menu !== this.menuName
      ) {
        this.src = this.icoActif;
      }
    },
    setOutImg() {
      if (
        this.$router.currentRoute.meta.menu &&
        this.$router.currentRoute.meta.menu !== this.menuName
      ) {
        this.src = this.ico;
      }
    },
    getClass() {
      if (this.$router.currentRoute.meta.menu) {
        const customClass =
          this.$router.currentRoute.meta.menu === this.menuName
            ? "bg-white text-black"
            : "bg-transparent text-white";

        this.linkClass = customClass + " text-sm";
      }
    },
    init: function () {
      if (this.$router.currentRoute.meta.menu) {
        this.src =
          this.$router.currentRoute.meta.menu === this.menuName
            ? this.getHoverImg
            : this.getDefaultImg;
      }
      this.ico = this.getDefaultImg;
      this.icoActif = this.getHoverImg;
      this.getClass();
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-item {
  // Display
  @apply flex items-center justify-center lg:justify-start lg:pl-8;
  // Sizing
  @apply w-14 h-11 lg:w-full lg:h-14;
  // Spacing
  @apply mx-auto mb-8;
  // Hover, etc ...
  @apply hover:bg-white group hover:text-black font-medium rounded-lg;
}
</style>
