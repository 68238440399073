import config from "@/config";
import {
  authHeader,
  fetchResponse,
  getDefaultOption,
  jsonStringify,
  fetchFileResponse,
} from "@/helpers";

export const publipostageService = {
  getPublipostages,
  createPublipostage,
  updatePublipostage,
  deletePublipostage,
  finishPublipostage,
  sendedPublipostage,
  patchParticipate,
  exportPublipostage,
  relanceAgence,
};

function getPublipostages(publipostageFilter, page) {
  return fetchResponse(
    `${
      config.apiUrl
    }/api/publipostages?${publipostageFilter.sanitarizeQueryParam()}&page=${page}`,
    getDefaultOption("GET", authHeader())
  );
}

function createPublipostage(publipostage) {
  const requestOptions = {
    method: "POST",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
    body: jsonStringify(publipostage),
  };

  return fetchResponse(`${config.apiUrl}/api/publipostages`, requestOptions);
}

function updatePublipostage(publipostage, publipostageUuid) {
  const requestOptions = {
    method: "PUT",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
    body: jsonStringify(publipostage),
  };

  return fetchResponse(
    `${config.apiUrl}/api/publipostages/${publipostageUuid}`,
    requestOptions
  );
}

function deletePublipostage(publipostageUuid) {
  const requestOptions = {
    method: "DELETE",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
  };

  return fetchResponse(
    `${config.apiUrl}/api/publipostages/${publipostageUuid}`,
    requestOptions
  );
}

function finishPublipostage(publipostageUuid) {
  const requestOptions = {
    method: "PATCH",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
  };

  return fetchResponse(
    `${config.apiUrl}/api/publipostages/${publipostageUuid}/finish`,
    requestOptions
  );
}

function sendedPublipostage(publipostageUuid) {
  const requestOptions = {
    method: "PATCH",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
  };

  return fetchResponse(
    `${config.apiUrl}/api/publipostages/${publipostageUuid}/sended`,
    requestOptions
  );
}

function patchParticipate(publipostageUuid, patchParticipates) {
  const requestOptions = {
    method: "PATCH",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
    body: jsonStringify(patchParticipates),
  };

  return fetchResponse(
    `${config.apiUrl}/api/publipostages/${publipostageUuid}/participes`,
    requestOptions
  );
}

function exportPublipostage(publipostageExportModel) {
  const requestOptions = {
    method: "POST",
    headers: Object.assign(
      { "Content-Type": "application/json", Accept: "application/zip" },
      authHeader()
    ),
    responseType: "blob",
    body: jsonStringify(publipostageExportModel),
  };

  return fetchFileResponse(
    `${config.apiUrl}/api/publipostages/exports`,
    requestOptions,
    "Export-Publipostage.zip"
  );
}

function relanceAgence(publipostageUuid, agencesUuid) {
  const requestOptions = {
    method: "POST",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
    body: jsonStringify(agencesUuid),
  };

  return fetchResponse(
    `${config.apiUrl}/api/publipostages/${publipostageUuid}/agences/relance`,
    requestOptions
  );
}
