<script>
import { Doughnut } from "vue-chartjs";
import baseChartMixin from "@/components/reporting/indicators/baseChartMixin";

export default {
  name: "DoughnutChart",
  extends: Doughnut,
  mixins: [baseChartMixin],
};
</script>
