export const loading = {
  namespaced: true,
  state: {
    isLoading: false,
  },
  actions: {
    startLoad({ commit }) {
      commit("startLoad");
    },
    stopLoad({ commit }) {
      commit("stopLoad");
    },
  },
  mutations: {
    startLoad(state) {
      state.isLoading = true;
    },
    stopLoad(state) {
      state.isLoading = false;
    },
  },
};
