<template>
  <div class="flex flex-wrap w-full p-7 px-12">
    <h1 class="text-2xl w-full text-secondary">Déclarer les ventes</h1>
    <div class="w-full flex justify-between items-center">
      <router-link to="/publipostages">
        <button class="mt-5 p-4 border border-gray-200 rounded-2xl shadow">
          <img
            src="@/assets/ico/retour-arrow.svg"
            alt="Retour en arrière"
            class="h-3 w-auto"
          />
        </button>
      </router-link>
    </div>
    <div
      v-if="isActif"
      class="w-full flex flex-wrap items-end justify-between mt-6"
    >
      <router-link
        :to="{
          name: 'GeneratePublipostage',
          params: {
            publipostageUuid: this.$route.params.publipostageUuid,
            agenceUuid: this.$route.params.agenceUuid,
          },
        }"
      >
        <button
          class="
            mt-5
            p-4
            border border-gray-200
            rounded-2xl
            shadow
            flex
            items-center
            text-secondary
          "
        >
          <img
            src="@/assets/ico/retour-arrow.svg"
            alt="Retour en arrière"
            class="h-3 w-auto mr-2"
          />
          Modifier l'adresse
        </button>
      </router-link>
      <div>
        <p v-if="participe.agence" class="ml-5 text-primary">
          Cette liste contient {{ adresses.length }} adresses. Seulement les
          {{ participe.agence.nbResultatsMax }} premières non-exclu seront
          utilisées.
        </p>
      </div>
    </div>
    <div
      v-if="adresses"
      class="flex flex-wrap mt-5 w-full p-5 border border-gray-200 rounded-2xl"
    >
      <div class="flex flex-wrap w-full justify-between items-center">
        <div class="flex flex-wrap">
          <p class="text-secondary">
            {{ participe.prenom }} {{ participe.nom }} -
            {{ participe.adresse }} - {{ participe.codePostal }}
            {{ participe.ville }}
          </p>
        </div>
      </div>
      <div class="flex flex-wrap w-full mt-5">
        <table class="w-full">
          <thead>
            <tr>
              <td />
              <td v-if="isActif" class="flex justify-center">
                <div
                  class="
                    flex
                    justify-center
                    items-center
                    p-2
                    border border-gray-200
                    rounded-lg
                    mb-5
                  "
                >
                  <div
                    class="
                      p-1
                      w-5
                      h-5
                      border border-gray-200
                      rounded-md
                      cursor-pointer
                    "
                    @click="toggleAllSwitch()"
                  >
                    <div
                      class="w-3 h-3 w-full h-full rounded-sm"
                      :class="
                        adressesSelected.length === adresses.length
                          ? 'bg-primary'
                          : ''
                      "
                    />
                  </div>
                </div>
              </td>
              <td>Nom</td>
              <td>Téléphone</td>
              <td>Adresse</td>
              <td>Code Postal</td>
              <td>Ville</td>
              <td>Distance</td>
              <td v-if="isEnvoye">Montant de la vente</td>
            </tr>
          </thead>
          <tbody class="mt-5">
            <tr
              v-for="(adresse, index) in adresses"
              :key="adresse.uuid"
              class="w-full border-t border-gray-200 h-16 min-h-full"
              :class="isActif ? 'cursor-pointer' : ''"
              @click="toggleSwitch(adresse.uuid)"
            >
              <td>{{ index + 1 }}.</td>
              <td v-if="isActif">
                <div class="flex justify-center items-center p-3">
                  <div
                    class="
                      p-1
                      w-5
                      h-5
                      border border-gray-200
                      rounded-md
                      cursor-pointer
                    "
                  >
                    <div
                      class="w-3 h-3 w-full h-full rounded-sm"
                      :class="
                        adressesSelected.includes(adresse.uuid)
                          ? 'bg-primary'
                          : ''
                      "
                    />
                  </div>
                </div>
              </td>
              <td>{{ adresse.adresse.nom }}</td>
              <td>{{ adresse.adresse.telephone }}</td>
              <td>{{ adresse.adresse.rue }}</td>
              <td>{{ adresse.adresse.codePostal }}</td>
              <td>{{ adresse.adresse.ville }}</td>
              <td>{{ displayDistance(adresse.km) }}</td>
              <td class="flex items-center p-2">
                <BaseModal
                  button-class="border border-gray-200 shadow-md p-3 rounded-lg mr-2"
                  modal-size="md:max-w-xl"
                  hide-footer
                  @validate="activateAdresse(false)"
                >
                  <template v-slot:button-content>
                    <font-awesome-icon icon="th-list" size="1x" />
                  </template>
                  <template v-slot:title>
                    <div
                      class="flex flex-wrap items-center justify-center w-full"
                    >
                      <h2 class="text-xl text-black">Déclaration des ventes</h2>
                    </div>
                  </template>
                  <template v-slot:content>
                    <div class="flex flex-wrap items-center w-full">
                      <div
                        class="
                          border border-gray-200
                          px-5
                          py-3
                          w-full
                          flex
                          items-center
                          justify-between
                        "
                      >
                        <div>Saisi le</div>
                        <div>Montant</div>
                      </div>
                      <div
                        v-for="vente in adresse.venteDeclares"
                        :key="vente.uuid"
                        class="
                          border border-gray-200
                          px-5
                          py-3
                          w-full
                          flex
                          items-center
                          justify-between
                        "
                      >
                        <div class="w-1/2 flex items-center">
                          <button
                            aria-haspopup="true"
                            class="
                              rounded-lg
                              shadow-lg
                              flex
                              items-center
                              justify-center
                              p-2
                              mr-2
                            "
                            @click="removeVente(vente.uuid)"
                          >
                            <img
                              src="@/assets/ico/trash.svg"
                              alt="Bouton de suppression"
                              class="h-5 w-auto"
                            />
                          </button>
                          <div>Le {{ dateFormat(vente.createdAt) }}</div>
                        </div>
                        <div class="w-1/2 flex justify-end">
                          <div
                            v-if="currentUpdatedVente.id !== vente.uuid"
                            class="cursor-pointer"
                            @dblclick="
                              handleDblClickVente(vente.uuid, vente.vente)
                            "
                          >
                            {{ vente.vente }} €
                          </div>
                          <div v-else class="flex items-center">
                            <input
                              ref="currentUpdatedVenteInput"
                              v-model="currentUpdatedVente.value"
                              type="number"
                              step="0.01"
                              class="
                                w-full
                                appearance-none
                                border border-primary
                                rounded-full
                                mr-2
                                max-w-xs
                                py-1
                                px-4
                              "
                              @focusout="handleFocusOutVente"
                            />
                            <span>€</span>
                          </div>
                        </div>
                      </div>
                      <div
                        class="
                          border border-gray-200
                          px-5
                          py-3
                          w-full
                          flex flex-wrap
                          items-center
                          justify-between
                        "
                      >
                        <div>Le {{ dateFormat() }}</div>
                        <div class="w-1/2 flex items-center">
                          <input
                            v-model="adresse.venteDeclare"
                            type="number"
                            step="0.01"
                            class="
                              w-full
                              appearance-none
                              border border-primary
                              rounded-full
                              mr-2
                              max-w-xs
                              py-1
                              px-4
                            "
                            @focusout="
                              changeVenteDeclare(
                                adresse.uuid,
                                $event.target.value
                              )
                            "
                          />
                          €
                        </div>
                        <div
                          v-if="
                            venteDeclareError.length > 0 &&
                            getErrors(adresse.uuid)
                          "
                          class="w-full mt-4"
                        >
                          <Error :title="getErrors(adresse.uuid)" />
                        </div>
                      </div>
                      <div class="w-full flex justify-end mt-5">
                        <button
                          id="saveButton"
                          class="rounded-full px-4 py-2 text-white"
                          @click="saveVenteDeclare(adresse.uuid)"
                        >
                          Enregistrer
                        </button>
                      </div>
                    </div>
                  </template>
                </BaseModal>
                <p>{{ getLastVenteDeclare(adresse) }}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import BaseModal from "@/components/modals/baseModal";
import PatchAdresseModel from "@/model/Publipostage/Participe/PatchAdresseModel";
import PatchAdresseVenteDeclareModel from "@/model/Publipostage/Participe/PatchAdresseVenteDeclareModel";
import Error from "@/components/forms/utils/Error";
import { venteDeclareService } from "@/services";

export default {
  name: "ListAdresses",
  components: {
    BaseModal,
    Error,
  },
  data() {
    return {
      adressesSelected: [],
      venteDeclare: [],
      venteDeclareError: [],
      currentUpdatedVente: {
        id: null,
        value: null,
      },
    };
  },
  computed: {
    adresses() {
      if (
        !this.isActif &&
        this.$store.state.participes.currentAdresses &&
        this.$store.state.participes.currentAdresses.length &&
        this.participe.agence &&
        this.participe.agence.nbResultatsMax
      ) {
        return this.$store.state.participes.currentAdresses
          .filter((adresse) => adresse.use)
          .slice(0, this.participe.agence.nbResultatsMax);
      }

      return this.$store.state.participes.currentAdresses;
    },
    nbAdresseSelectionnee() {
      if (this.adressesSelected.length === 1) {
        return "1 adresse séléctionnée";
      } else if (this.adressesSelected.length > 0) {
        return this.adressesSelected.length + " adresses séléctionnées";
      }

      return "";
    },
    participe() {
      return this.$store.state.participes.currentParticipe;
    },
    isEnvoye() {
      if (this.participe && this.participe.publipostage) {
        return this.participe.publipostage.status === "ENVOYE";
      }

      return false;
    },
    isActif() {
      if (this.participe && this.participe.publipostage) {
        return this.participe.publipostage.status === "ACTIF";
      }

      return false;
    },
  },
  async created() {
    const { dispatch } = this.$store;

    await dispatch({
      type: "participes/getAdresses",
      publipostageUuid: this.$route.params.publipostageUuid,
      agenceUuid: this.$route.params.agenceUuid,
    });

    await dispatch({
      type: "participes/getParticipe",
      publipostageUuid: this.$route.params.publipostageUuid,
      agenceUuid: this.$route.params.agenceUuid,
    });
  },
  methods: {
    dateFormat(date = null) {
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };

      const objDate = date === null ? new Date() : new Date(date);

      return objDate.toLocaleDateString("fr-FR", options);
    },
    toggleSwitch(adresseUuid) {
      if (this.isActif) {
        if (this.adressesSelected.includes(adresseUuid)) {
          this.adressesSelected.splice(
            this.adressesSelected.indexOf(adresseUuid),
            1
          );
        } else {
          this.adressesSelected.push(adresseUuid);
        }
      }
    },
    toggleAllSwitch() {
      if (this.adressesSelected.length !== this.adresses.length) {
        this.adressesSelected = [];
        this.adresses.map((adresse) => {
          this.adressesSelected.push(adresse.uuid);
        });
      } else {
        this.adressesSelected = [];
      }
    },
    displayDistance(distance) {
      const distanceMeter = distance * 1000;
      return distanceMeter.toFixed(0) + " m";
    },
    activateAdresse(activate = true) {
      const { dispatch } = this.$store;
      const patchAdresses = this.adressesSelected.map(
        (adresse) => new PatchAdresseModel(adresse, activate)
      );

      dispatch({
        type: "participes/patchAdresses",
        publipostageUuid: this.$route.params.publipostageUuid,
        agenceUuid: this.$route.params.agenceUuid,
        patchAdressesDto: patchAdresses,
      });

      this.$refs.buttonRetirer.closeModal();
      this.$refs.buttonIntegrer.closeModal();
      this.adressesSelected = [];
    },
    changeVenteDeclare(uuid, value) {
      this.venteDeclare[uuid] = value;
    },
    async saveVenteDeclare(adresseUuid) {
      this.venteDeclareError = [];

      if (!this.venteDeclare[adresseUuid]) {
        return;
      }
      const { dispatch } = this.$store;
      const patchAdresses = [];

      patchAdresses.push(
        new PatchAdresseVenteDeclareModel(
          adresseUuid,
          this.venteDeclare[adresseUuid].length === 0
            ? null
            : this.venteDeclare[adresseUuid]
        )
      );

      if (patchAdresses.length > 0) {
        await dispatch({
          type: "participes/patchVenteDeclare",
          publipostageUuid: this.$route.params.publipostageUuid,
          agenceUuid: this.$route.params.agenceUuid,
          patchAdressesDto: patchAdresses,
        }).catch((errors) => {
          for (const error in errors) {
            let value = "";
            for (const errorInfo in errors[error].errorInfos.errors) {
              value =
                value +
                errors[error].errorInfos.errors[errorInfo].message +
                " ";
            }
            this.venteDeclareError.push({
              uuid: errors[error].adresse,
              errors: value,
            });
          }
        });

        this.venteDeclare = [];
      }
    },
    getClass(adresse) {
      return adresse.use ? "" : "opacity-50 line-through";
    },
    getLastVenteDeclare(adresse) {
      let venteDeclare = null;
      adresse.venteDeclares.forEach((venteDeclareObject) => {
        if (null === venteDeclare) {
          venteDeclare = venteDeclareObject;
        } else {
          if (venteDeclare.createdAt < venteDeclareObject.createdAt) {
            venteDeclare = venteDeclareObject;
          }
        }
      });

      return venteDeclare === null ? "" : venteDeclare.vente + " €";
    },
    getErrors(uuid) {
      var error = this.venteDeclareError.filter(function (error) {
        return error.uuid === uuid;
      });

      return error.length > 0 ? error[0].errors : null;
    },
    async removeVente(uuid) {
      await venteDeclareService.remove(uuid);
      this.$store.dispatch("participes/getAdresses", {
        publipostageUuid: this.$route.params.publipostageUuid,
        agenceUuid: this.$route.params.agenceUuid,
      });
    },
    handleDblClickVente(uuid, value) {
      this.currentUpdatedVente.id = uuid;
      this.currentUpdatedVente.value = value;
      this.$nextTick(() => {
        this.$refs.currentUpdatedVenteInput[0].focus();
      });
    },
    async handleFocusOutVente() {
      await venteDeclareService.update(this.currentUpdatedVente.id, {
        vente: this.currentUpdatedVente.value,
      });
      this.$store.dispatch("participes/getAdresses", {
        publipostageUuid: this.$route.params.publipostageUuid,
        agenceUuid: this.$route.params.agenceUuid,
      });
      this.currentUpdatedVente.id = null;
      this.currentUpdatedVente.value = null;
    },
  },
};
</script>

<style lang="scss" scoped>
#saveButton {
  background: transparent linear-gradient(268deg, #f5264c 0%, #e31bb1 100%) 0%
    0% no-repeat padding-box;

  &:hover {
    background: transparent linear-gradient(268deg, #e31bb1 0%, #f5264c 100%) 0%
      0% no-repeat padding-box;
  }
}
</style>
