import { userService } from "@/services";
import { saveAs } from "file-saver";

export function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        const user = JSON.parse(localStorage.getItem("user"));
        if (user && user.refreshToken) {
          userService.loginWithRefreshToken().then();
        }
      }

      if (response.status === 400 || response.status === 417) {
        return Promise.reject(data);
      }

      const error =
        "Une erreur inconnue est survenu, veuillez réessayer plus tard, si le problème persiste, contacter un administrateur ";
      return Promise.reject(error);
    }

    return data;
  });
}

export function handleFileResponse(response, filename) {
  if (!response.ok) {
    const error =
      "Une erreur inconnue est survenu, veuillez réessayer plus tard, si le problème persiste, contacter un administrateur ";
    return Promise.reject(error);
  }

  response.blob().then((blob) => {
    saveAs(blob, filename);
  });
}

export function fetchFileResponse(uri, requestOptions, filename) {
  return fetch(uri, requestOptions)
    .catch((error) => {
      error =
        "Une erreur inconnue est survenu, veuillez réessayer plus tard, si le problème persiste, contacter un administrateur ";
      return Promise.reject(error);
    })
    .then((response) => handleFileResponse(response, filename));
}

export function fetchResponse(uri, requestOptions) {
  return fetch(uri, requestOptions)
    .catch((error) => {
      error =
        "Une erreur inconnue est survenu, veuillez réessayer plus tard, si le problème persiste, contacter un administrateur ";
      return Promise.reject(error);
    })
    .then(handleResponse);
}

export function getDefaultOption(method, auth, body = {}) {
  let requestOption = {
    headers: { "Content-Type": "application/json" },
    method: method,
  };

  if (method !== "GET") {
    requestOption.body = JSON.stringify(body);
  }

  if (auth) {
    Object.assign(requestOption.headers, auth);
  }
  return requestOption;
}
