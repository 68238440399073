import { userService } from "@/services";
import router from "@/router";

const user = JSON.parse(localStorage.getItem("user"));
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: {}, user: null };

export const authentication = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ dispatch, commit }, { username, password }) {
      commit("loginRequest", { username });

      dispatch("alert/clear", "", { root: true });
      dispatch("loading/startLoad", "", { root: true });
      userService
        .login(username, password)
        .then(
          (user) => {
            commit("loginSuccess", user);
            router.push("/publipostages");
          },
          (error) => {
            commit("loginFailure", error);
            dispatch("alert/error", error, { root: true });
          }
        )
        .finally(() => {
          dispatch("loading/stopLoad", "", { root: true });
        });
    },
    logout({ commit }) {
      userService.logout();
      commit("logout");
      router.push("/login");
    },
    requestReset({ dispatch }, { email }) {
      dispatch("alert/clear", "", { root: true });
      dispatch("loading/startLoad", "", { root: true });
      userService
        .requestPassword(email)
        .then(() => {
          dispatch(
            "alert/success",
            "Un email de réinitialisation à été envoyé à votre adresse email.",
            { root: true }
          );
        })
        .catch((error) => {
          dispatch("alert/error", error, { root: true });
        })
        .finally(() => {
          dispatch("loading/stopLoad", "", { root: true });
        });
    },
    reset({ dispatch }, { token, password }) {
      dispatch("loading/startLoad", "", { root: true });
      userService
        .resetPassword(token, password)
        .then(() => {
          dispatch("alert/success", "Mot de passe réinitialisé", {
            root: true,
          });
        })
        .catch((error) => {
          dispatch("alert/error", error, { root: true });
        })
        .finally(() => {
          dispatch("loading/stopLoad", "", { root: true });
        });
    },
  },
  mutations: {
    loginRequest(state, user) {
      state.status = { loggingIn: true };
      state.user = user;
    },
    loginSuccess(state, user) {
      state.status = { loggedIn: true };
      state.user = user;
    },
    loginFailure(state) {
      state.status = {};
      state.user = null;
    },
    logout(state) {
      state.status = {};
      state.user = null;
    },
  },
};
