<template>
  <div class="flex flex-wrap">
    <div
      v-show="mustBeSelected && agences.length > 0"
      class="flex w-full items-center mt-5 cursor-pointer"
    >
      <div
        class="
          flex
          justify-center
          items-center
          p-3
          border border-gray-200
          rounded-lg
        "
      >
        <div
          class="p-1 w-6 h-6 border border-gray-200 rounded-md"
          @click="toggleAllSwitch()"
        >
          <div
            class="w-4 h-4 w-full h-full rounded-sm"
            :class="
              agencesSelected.length === agences.length ? 'bg-primary' : ''
            "
          />
        </div>
      </div>
      <p class="text-primary text-md ml-4" @click="toggleAllSwitch()">
        Sélectionner
      </p>
    </div>
    <div
      v-for="participe in sortedAgences"
      :key="participe.agence.uuid"
      class="flex w-full mt-5 cursor-pointer"
      @click="toggleSwitch(participe.agence.uuid)"
    >
      <div v-show="mustBeSelected" class="flex justify-center items-center p-3">
        <div class="p-1 w-6 h-6 border border-gray-200 rounded-md">
          <div
            class="w-4 h-4 w-full h-full rounded-sm"
            :class="
              agencesSelected.includes(participe.agence.uuid)
                ? 'bg-primary'
                : ''
            "
          />
        </div>
      </div>
      <div
        class="
          flex
          p-5
          border border-gray-200
          rounded-lg
          flex-grow
          items-center
        "
      >
        <img
          src="@/assets/ico/liste-agences.svg"
          alt="icone des paramètres"
          class="w-8 mr-4"
        />
        <div class="flex items-center justify-between w-full">
          <h6>{{ participe.agence.nom }}</h6>
          <div v-if="publipostage && publipostage.status === 'ENVOYE'">
            <router-link
              :to="{
                name: 'DeclareVente',
                params: {
                  publipostageUuid: publipostage.uuid,
                  agenceUuid: participe.agence.uuid,
                },
              }"
            >
              <button class="bg-indigo-900 text-white p-2 rounded-full">
                <font-awesome-icon icon="comment-dollar" size="1x" />
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div
      v-show="mustBeSelected && agences.length > 0"
      class="flex w-full mt-5 justify-end"
    >
      <slot name="action-content" />
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectAgence",
  components: {},
  props: {
    agences: {
      type: Array,
      required: true,
    },
    agencesSelected: {
      type: Array,
      default: () => [],
    },
    mustBeSelected: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: "",
    },
    venteDeclare: {
      type: Boolean,
      default: false,
    },
    publipostage: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    sortedAgences() {
      return [...this.agences].sort((a, b) =>
        a.agence.nom.localeCompare(b.agence.nom, "fr", {
          sensitivity: "base",
        })
      );
    },
  },
  methods: {
    toggleSwitch(agenceUuid) {
      if (this.agencesSelected.includes(agenceUuid)) {
        this.agencesSelected.splice(
          this.agencesSelected.indexOf(agenceUuid),
          1
        );
        this.$emit("update:agencesSelected", this.agencesSelected);
      } else {
        this.agencesSelected.push(agenceUuid);
        this.$emit("update:agencesSelected", this.agencesSelected);
      }
    },
    async toggleAllSwitch() {
      if (this.agencesSelected.length !== this.agences.length) {
        await this.$emit("update:agencesSelected", []);
        this.agences.map((participe) => {
          this.agencesSelected.push(participe.agence.uuid);
          this.$emit("update:agencesSelected", this.agencesSelected);
        });
      } else {
        this.$emit("update:agencesSelected", []);
      }
    },
  },
};
</script>
