<template>
  <div class="counter-container">
    <span class="counter-value">{{ displayedValue }}</span>
  </div>
</template>

<script>
import { currencyFormatter } from "@/helpers";

export default {
  name: "Counter",
  props: {
    value: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      animationValue: null,
      animation: null,
    };
  },
  computed: {
    displayedValue() {
      return currencyFormatter.format(this.animationValue);
    },
  },
  watch: {
    value: {
      handler() {
        this.animateValue();
      },
      immediate: true,
    },
  },
  methods: {
    animateValue() {
      const duration = 1500;
      if (!this.value) {
        this.animationValue = 0;
        return;
      }
      let current = 0;
      let increment = this.value > 0 ? 1 : -1;
      let stepTime = Math.abs(Math.floor(duration / this.value));
      if (stepTime < 10) {
        stepTime = 10;
        increment =
          this.value > 0
            ? this.value / (duration / stepTime)
            : -(this.value / (duration / stepTime));
        increment = Math.abs(Math.floor(increment));
      }
      if (this.animation !== null) {
        clearInterval(this.animation);
      }
      this.animation = setInterval(() => {
        current += increment;
        if (
          (increment > 0 && current >= this.value) ||
          (increment < 0 && current <= this.value)
        ) {
          this.animationValue = this.value;
          clearInterval(this.animation);
        } else {
          this.animationValue = current;
        }
      }, stepTime);
    },
  },
};
</script>

<style lang="scss" scoped>
.counter-container {
  @apply w-full h-full flex justify-center items-center mb-6;

  .counter-value {
    @apply border-l-4 border-primary font-bold pl-6 text-4xl py-2;
  }
}
</style>
