import { participeService } from "@/services";
import AgenceFilter from "@/model/AgenceFilter";

export const participes = {
  namespaced: true,
  state: {
    participes: {},
    currentParticipe: {},
    currentAdresses: {},
  },
  actions: {
    async getParticipes({ commit, dispatch }, { participeFilter }) {
      await dispatch(
        {
          type: "agences/getAgences",
          agenceFilter: new AgenceFilter(),
        },
        { root: true }
      );

      let page = 1;
      let arrayParticipes = [];
      let total = 0;

      do {
        await participeService
          .getParticipes(participeFilter, page)
          .then((participes) => {
            total = participes.pages;
            page++;
            arrayParticipes.push(...participes.results);
          });
      } while (total >= page);

      commit("setParticipes", arrayParticipes);
      dispatch("loading/stopLoad", "", { root: true });
    },
    async getParticipe({ commit, dispatch }, { publipostageUuid, agenceUuid }) {
      dispatch("loading/startLoad", "", { root: true });

      return new Promise((resolve, reject) => {
        participeService
          .getParticipe(publipostageUuid, agenceUuid)
          .then((participe) => {
            commit("setCurrentParticipe", participe);
          })
          .finally(() => {
            dispatch("loading/stopLoad", "", { root: true });
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async getAdresses({ commit, dispatch }, { publipostageUuid, agenceUuid }) {
      dispatch("loading/startLoad", "", { root: true });

      let page = 1;
      let arrayAdresses = [];
      let total = 0;

      do {
        await participeService
          .getAdresses(publipostageUuid, agenceUuid, page)
          .then((adresses) => {
            total = adresses.pages;
            page++;
            arrayAdresses.push(...adresses.results);
          });
      } while (total >= page);

      commit("setCurrentAdresses", arrayAdresses);
      dispatch("loading/stopLoad", "", { root: true });
    },
    patchAdresses(
      { dispatch },
      { publipostageUuid, agenceUuid, patchAdressesDto }
    ) {
      dispatch("loading/startLoad", "", { root: true });

      participeService
        .patchAdresses(publipostageUuid, agenceUuid, patchAdressesDto)
        .finally(() => {
          dispatch({
            type: "getAdresses",
            publipostageUuid: publipostageUuid,
            agenceUuid: agenceUuid,
          });
        });
    },
    patchVenteDeclare(
      { dispatch },
      { publipostageUuid, agenceUuid, patchAdressesDto }
    ) {
      dispatch("loading/startLoad", "", { root: true });

      return participeService
        .patchAdressesVenteDeclare(
          publipostageUuid,
          agenceUuid,
          patchAdressesDto
        )
        .then((results) => {
          if (results.length > 0) {
            for (const result in results) {
              if (results[result].success === false) {
                return Promise.reject(results);
              }
            }
          }
        })
        .finally(() => {
          dispatch({
            type: "getAdresses",
            publipostageUuid: publipostageUuid,
            agenceUuid: agenceUuid,
          });
        });
    },
    exportPublipostages({ dispatch }, { publipostageUuid, agenceUuid }) {
      dispatch("loading/startLoad", "", { root: true });

      participeService
        .exportParticipe(publipostageUuid, agenceUuid)
        .finally(() => {
          dispatch("loading/stopLoad", "", { root: true });
        });
    },
  },
  mutations: {
    setParticipes(state, participes) {
      state.participes = participes;
    },
    setCurrentParticipe(state, participe) {
      state.currentParticipe = participe;
    },
    setCurrentAdresses(state, adresses) {
      state.currentAdresses = adresses;
    },
  },
};
