var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-wrap"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.mustBeSelected && _vm.agences.length > 0),expression:"mustBeSelected && agences.length > 0"}],staticClass:"flex w-full items-center mt-5 cursor-pointer"},[_c('div',{staticClass:"\n        flex\n        justify-center\n        items-center\n        p-3\n        border border-gray-200\n        rounded-lg\n      "},[_c('div',{staticClass:"p-1 w-6 h-6 border border-gray-200 rounded-md",on:{"click":function($event){return _vm.toggleAllSwitch()}}},[_c('div',{staticClass:"w-4 h-4 w-full h-full rounded-sm",class:_vm.agencesSelected.length === _vm.agences.length ? 'bg-primary' : ''})])]),_c('p',{staticClass:"text-primary text-md ml-4",on:{"click":function($event){return _vm.toggleAllSwitch()}}},[_vm._v(" Sélectionner ")])]),_vm._l((_vm.sortedAgences),function(participe){return _c('div',{key:participe.agence.uuid,staticClass:"flex w-full mt-5 cursor-pointer",on:{"click":function($event){return _vm.toggleSwitch(participe.agence.uuid)}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.mustBeSelected),expression:"mustBeSelected"}],staticClass:"flex justify-center items-center p-3"},[_c('div',{staticClass:"p-1 w-6 h-6 border border-gray-200 rounded-md"},[_c('div',{staticClass:"w-4 h-4 w-full h-full rounded-sm",class:_vm.agencesSelected.includes(participe.agence.uuid)
              ? 'bg-primary'
              : ''})])]),_c('div',{staticClass:"\n        flex\n        p-5\n        border border-gray-200\n        rounded-lg\n        flex-grow\n        items-center\n      "},[_c('img',{staticClass:"w-8 mr-4",attrs:{"src":require("@/assets/ico/liste-agences.svg"),"alt":"icone des paramètres"}}),_c('div',{staticClass:"flex items-center justify-between w-full"},[_c('h6',[_vm._v(_vm._s(participe.agence.nom))]),(_vm.publipostage && _vm.publipostage.status === 'ENVOYE')?_c('div',[_c('router-link',{attrs:{"to":{
              name: 'DeclareVente',
              params: {
                publipostageUuid: _vm.publipostage.uuid,
                agenceUuid: participe.agence.uuid,
              },
            }}},[_c('button',{staticClass:"bg-indigo-900 text-white p-2 rounded-full"},[_c('font-awesome-icon',{attrs:{"icon":"comment-dollar","size":"1x"}})],1)])],1):_vm._e()])])])}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.mustBeSelected && _vm.agences.length > 0),expression:"mustBeSelected && agences.length > 0"}],staticClass:"flex w-full mt-5 justify-end"},[_vm._t("action-content")],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }