import config from "@/config";
import {
  authHeader,
  fetchFileResponse,
  fetchResponse,
  getDefaultOption,
  jsonStringify,
} from "@/helpers";

export const participeService = {
  getParticipes,
  getParticipe,
  generateParticipe,
  getAdresses,
  patchAdresses,
  patchAdressesVenteDeclare,
  exportParticipe,
};

function getParticipes(participeFilter, page) {
  return fetchResponse(
    `${
      config.apiUrl
    }/api/publipostages/participes?${participeFilter.sanitarizeQueryParam()}&page=${page}`,
    getDefaultOption("GET", authHeader())
  );
}

function getParticipe(publipostageUuid, agenceUuid) {
  return fetchResponse(
    `${config.apiUrl}/api/publipostages/${publipostageUuid}/agences/${agenceUuid}/participe`,
    getDefaultOption("GET", authHeader())
  );
}

function generateParticipe(publipostageUuid, agenceUuid, participe) {
  const requestOptions = {
    method: "POST",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
    body: jsonStringify(participe),
  };

  return fetchResponse(
    `${config.apiUrl}/api/publipostages/${publipostageUuid}/agences/${agenceUuid}/participe/generate`,
    requestOptions
  );
}

function getAdresses(publipostageUuid, agenceUuid, page) {
  return fetchResponse(
    `${config.apiUrl}/api/publipostages/${publipostageUuid}/agences/${agenceUuid}/participe/adresses?page=${page}`,
    getDefaultOption("GET", authHeader())
  );
}

function patchAdresses(publipostageUuid, agenceUuid, patchAdressesDto) {
  const requestOptions = {
    method: "PATCH",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
    body: jsonStringify(patchAdressesDto),
  };

  return fetchResponse(
    `${config.apiUrl}/api/publipostages/${publipostageUuid}/agences/${agenceUuid}/participe/adresses`,
    requestOptions
  );
}

function patchAdressesVenteDeclare(
  publipostageUuid,
  agenceUuid,
  patchAdressesDto
) {
  const requestOptions = {
    method: "PATCH",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
    body: jsonStringify(patchAdressesDto),
  };

  return fetchResponse(
    `${config.apiUrl}/api/publipostages/${publipostageUuid}/agences/${agenceUuid}/participe/adresses/vente-declare`,
    requestOptions
  );
}

function exportParticipe(publipostageUuid, agenceUuid) {
  const requestOptions = {
    method: "GET",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
  };

  return fetchFileResponse(
    `${config.apiUrl}/api/publipostages/${publipostageUuid}/agences/${agenceUuid}/participe/export`,
    requestOptions,
    "Export-Publipostage.zip"
  );
}
