<template>
  <div
    v-show="title"
    class="
      flex
      items-center
      text-white
      px-3
      py-1
      border-0
      rounded-full
      mb-4
      bg-red-500
      w-full
      mt-1
    "
  >
    <span class="text-xl mr-3 align-middle">
      <font-awesome-icon icon="times" />
    </span>
    <span class="text-sm">
      {{ title }}
    </span>
  </div>
</template>

<script>
export default {
  name: "Error",
  props: {
    title: {
      type: String,
      default: null,
    },
  },
};
</script>
