export default class PatchAdresseModel {
  constructor(adresse, use) {
    this.adresse = adresse;
    this.use = use;
  }

  getAdresse() {
    return this.adresse;
  }

  getUse() {
    return this.use;
  }
}
