<template>
  <div>
    <Error :title="errors.commonError" />
    <form class="flex flex-wrap -mx-3">
      <div class="w-1/2 px-3 mb-8">
        <label for="nom" class="font-semibold px-1 mb-2">Nom</label>
        <input
          id="nom"
          v-model="formNom"
          name="nom"
          type="text"
          class="form-input"
          required
        />
        <Error :title="errors.nom" />
      </div>
      <div class="w-1/2 px-3 mb-8">
        <label for="prenom" class="font-semibold px-1 mb-2">Prénom</label>
        <input
          id="prenom"
          v-model="formPrenom"
          name="prenom"
          type="text"
          class="form-input"
          required
        />
        <Error :title="errors.prenom" />
      </div>
      <div class="w-1/2 px-3 mb-8">
        <label for="email" class="font-semibold px-1 mb-2">Email</label>
        <input
          id="email"
          v-model="formEmail"
          name="email"
          type="text"
          class="form-input"
          required
        />
        <Error :title="errors.email" />
      </div>
      <div class="w-1/2 px-3 mb-8">
        <Select :data="roles" :value="role.value" @valueSelect="onValueSelect">
          <template v-slot:SelectLabel>
            <span class="font-semibold">Rôle</span>
          </template>
        </Select>
        <Error :title="errors.role" />
      </div>
      <div class="w-full px-3 mb-8">
        <label class="font-semibold px-1 mb-2">Agence</label>
        <multiselect
          v-model="formAgences"
          placeholder="Rechercher une agence"
          label="label"
          track-by="value"
          :options="agencesOptions"
          :multiple="true"
          group-values="choices"
          group-label="label"
          :disabled="agenceIsDisable"
          select-label=""
          deselect-label=""
          selected-label=""
          :show-no-results="false"
        />
        <Error :title="errors.agences" />
      </div>
    </form>
  </div>
</template>

<script>
import Select from "@/components/forms/utils/Select";
import Error from "@/components/forms/utils/Error";
import UserModel from "@/model/user";
import { catchErrors } from "@/helpers";
import { mapState } from "vuex";

export default {
  name: "UserForm",
  components: {
    Select,
    Error,
  },
  props: {
    nom: {
      type: String,
      default: "",
    },
    prenom: {
      type: String,
      default: "",
    },
    email: {
      type: String,
      default: "",
    },
    usersRoles: {
      type: Array,
      default: () => {
        return [];
      },
    },
    usersAgences: {
      type: Array,
      default: () => {
        return [];
      },
    },
    userUuid: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      formNom: "",
      formPrenom: "",
      formEmail: "",
      role: { key: "", value: "---" },
      formAgences: [],
      roles: [
        { key: "", value: "---" },
        { key: "admin", value: "Administrateur" },
        { key: "secretaire", value: "Secrétaire" },
        { key: "da", value: "Directeur d'agence" },
        { key: "dz", value: "Directeur de zone" },
      ],
      agenceIsDisable: false,
      errors: {
        commonError: null,
        nom: null,
        prenom: null,
        email: null,
        role: null,
        agences: null,
      },
    };
  },
  computed: {
    ...mapState({
      agences: (state) => state.agences.agences,
    }),

    agencesOptions() {
      return this.agences.reduce((acc, agence) => {
        const indexBrand = acc.findIndex(
          (item) => item.code === agence.marque.code
        );
        if (indexBrand === -1) {
          acc.push({
            code: agence.marque.code,
            label: agence.marque.nom,
            choices: [{ label: agence.nom, value: agence.uuid }],
          });
        } else {
          acc[indexBrand].choices.push({
            label: agence.nom,
            value: agence.uuid,
          });
        }
        return acc;
      }, []);
    },
  },
  mounted() {
    this.formNom = this.nom;
    this.formPrenom = this.prenom;
    this.formEmail = this.email;

    if (this.usersRoles.length > 0) {
      for (const role of this.usersRoles) {
        switch (role) {
          case "ROLE_ADMIN":
            this.agenceIsDisable = true;
            this.role = { key: "admin", value: "Administrateur" };
            break;
          case "ROLE_SECRETAIRE":
            this.role = { key: "secretaire", value: "Secrétaire" };
            break;
          case "ROLE_DA":
            this.role = { key: "da", value: "Directeur d'agence" };
            break;
          case "ROLE_DZ":
            this.role = { key: "dz", value: "Directeur de zone" };
            break;
          default:
            this.role = { key: "", value: "---" };
        }
      }
    }

    this.usersAgences.map((agence) => {
      this.formAgences.push({ label: agence.nom, value: agence.uuid });
    });
  },
  methods: {
    onValueSelect(value) {
      this.agenceIsDisable = false;

      if (value.key === "admin") {
        this.agenceIsDisable = true;
        this.formAgences = [];
      }

      this.role = value;
    },
    handleEditSubmit() {
      this.resetErrorsMessage();
      const agences = this.formAgences.map((agence) => {
        return agence.value;
      });
      const { formNom, formPrenom, formEmail, role, userUuid } = this;
      const { dispatch } = this.$store;
      const user = new UserModel(
        formNom,
        formPrenom,
        formEmail,
        role.key,
        agences
      );

      if (userUuid) {
        dispatch("users/updateUser", { user, userUuid }).catch((errors) =>
          catchErrors(errors, this.errors)
        );
      }
    },
    handleCreateSubmit() {
      this.resetErrorsMessage();
      const agences = this.formAgences.map((agence) => {
        return agence.value;
      });
      const { formNom, formPrenom, formEmail, role } = this;
      const { dispatch } = this.$store;
      const user = new UserModel(
        formNom,
        formPrenom,
        formEmail,
        role.key,
        agences
      );
      dispatch("users/createUser", { user }).catch((errors) =>
        catchErrors(errors, this.errors)
      );
    },
    resetErrorsMessage() {
      Object.keys(this.errors).forEach((k) => (this.errors[k] = null));
    },
  },
};
</script>
