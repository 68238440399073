<template>
  <div :class="buttonSize">
    <button :class="getClass()" aria-haspopup="true" @click="toggleModal()">
      <slot name="button-content" />
    </button>
    <div
      v-if="showModal"
      class="
        fixed
        z-20
        inset-0
        overflow-y-auto
        outline-none
        focus:outline-none
        justify-center
        items-center
        flex
      "
    >
      <div class="modal">
        <button
          v-if="canClose"
          class="modal-close-button rotate-45 text-pink-600 text-lg"
          style="
            font-family: 'Poppins-SemiBold', 'sans-serif';
            transform: rotate(45deg);
          "
          @click="toggleModal()"
        >
          +
        </button>
        <div :class="modalSize">
          <!--header-->
          <div class="modal-header">
            <slot name="title" />
          </div>
          <!--body-->
          <div class="modal-body">
            <slot name="content" />
          </div>
          <!--footer-->
          <div v-if="!hideFooter" class="modal-footer mt-9 mb-9">
            <button
              v-if="canClose"
              class="w-full text-white py-4 rounded-full hover:shadow-lg"
              type="button"
              style="transition: all 0.15s ease; background-color: #c8c8c8"
              @click="toggleModal()"
            >
              Annuler
            </button>
            <button
              class="
                w-full
                btn-success
                text-white
                py-4
                rounded-full
                hover:shadow-lg
              "
              type="button"
              style="transition: all 0.15s ease"
              @click="validate"
            >
              <slot name="validation-content"> Valider </slot>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModal" class="opacity-25 fixed inset-0 z-10 bg-black" />
  </div>
</template>

<script>
export default {
  name: "BaseModal",
  props: {
    buttonClass: {
      type: String,
      default:
        "font-semibold flex items-center rounded-xl border h-12 lg:h-15 px-4 shadow-lg bg-secondary text-white",
    },
    buttonSize: {
      type: String,
      default: "w-auto",
    },
    modalSize: {
      type: String,
      default: "lg:max-w-2xl",
    },
    active: {
      type: Boolean,
      default: true,
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    canClose: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    toggleModal() {
      if (!this.active) {
        return;
      }
      this.showModal = !this.showModal;
    },
    closeModal() {
      this.showModal = false;
    },
    validate() {
      this.$emit("validate");
    },
    getClass() {
      let cssClass = this.buttonClass;

      if (!this.active) {
        cssClass = cssClass + " opacity-50 cursor-not-allowed";
      }

      return cssClass;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-success {
  background: transparent linear-gradient(268deg, #f5264c 0%, #e31bb1 100%) 0%
    0% no-repeat padding-box;
}

.modal {
  @apply border-0 rounded-lg shadow-lg relative bg-white relative;
  max-width: 90vw;

  .modal-close-button {
    @apply absolute text-xl text-primary font-semibold top-2 right-4 z-20;
  }

  > div {
    @apply overflow-y-auto my-5 lg:my-7 px-10 lg:px-14;
    max-height: calc(90vh - 3.5rem);

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.2);
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }

    .modal-header {
      @apply text-center mb-8 relative;
    }

    .modal-footer {
      @apply space-y-2;
    }
  }
}
</style>
