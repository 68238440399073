var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative rounded-l-xl rounded-r-2xl w-full"},[_c('div',{staticClass:"absolute top-0 left-0 rounded-l-lg h-full flex-shrink-0 w-2",class:_vm.getCardsClass(_vm.participe.publipostage.marque)}),_c('div',{staticClass:"flex flex-col p-3 pl-5 h-full rounded-l-xl rounded-r-2xl",staticStyle:{"box-shadow":"0px 3px 6px #00000029"}},[_c('div',{staticClass:"flex justify-between items-center"},[_c('div',{staticClass:"flex flex-wrap mb-3 self-start"},[_c('span',{staticClass:"flex-grow",staticStyle:{"font-family":"'poppins-SemiBold', 'sans-serif'"}},[_vm._v(_vm._s(_vm.participe.publipostage.nom))]),_c('span',{staticClass:"w-full text-sm flex-grow"},[_vm._v(_vm._s(_vm.participe.agence.nom))])]),_c('div',{staticClass:"flex flex-wrap min-w-max"},[(
            _vm.participe.isDone && _vm.participe.publipostage.status === 'ENVOYE'
          )?_c('span',{staticClass:"\n            px-4\n            py-1\n            rounded-full\n            flex-none\n            text-xs\n            w-full\n            text-center\n            mt-1\n          ",staticStyle:{"background":"#e1ebff","color":"#267eb1"}},[_vm._v("Envoyé")]):_vm._e(),(_vm.participe.isDone)?_c('span',{staticClass:"\n            px-4\n            py-1\n            rounded-full\n            flex-none\n            text-xs\n            w-full\n            text-center\n            mt-1\n          ",staticStyle:{"background":"#ebfbef","color":"#7bd96e"}},[_vm._v("Validé")]):_vm._e(),(
            !_vm.participe.isDone && _vm.participe.publipostage.status !== 'ACTIF'
          )?_c('span',{staticClass:"\n            px-4\n            py-1\n            rounded-full\n            flex-none\n            text-xs\n            bg-red-100\n            text-red-500\n            w-full\n            text-center\n            mt-1\n          "},[_vm._v("Délai dépassé")]):_vm._e(),(
            !_vm.participe.isDone && _vm.participe.publipostage.status === 'ACTIF'
          )?_c('span',{staticClass:"\n            px-4\n            py-1\n            rounded-full\n            flex-none\n            text-xs\n            w-full\n            text-center\n            mt-1\n          ",staticStyle:{"background":"#feeff6","color":"#ff3693"}},[_vm._v("À faire")]):_vm._e()])]),_c('div',{staticClass:"flex items-center justify-between mt-2 mb-3"},[_c('div',{staticClass:"flex"},[_c('img',{staticClass:"w-3.5 mr-2",attrs:{"src":require("@/assets/ico/date.svg"),"alt":"icone date"}}),_c('p',{staticClass:"text-sm"},[_vm._v(" "+_vm._s(_vm.dateFormat(_vm.participe.publipostage.dateLimite))+" ")])]),_c('div',{staticClass:"flex"},[_c('p',{staticClass:"text-sm"},[_vm._v(" "+_vm._s(_vm.nbJoursRestant( _vm.participe.publipostage.dateLimite, _vm.participe.publipostage ))+" ")])])]),(_vm.participe.isDone && _vm.participe.publipostage.status === 'ENVOYE')?_c('span',{staticClass:"w-full flex flex-wrap justify-center text-sm flex-grow mt-2"},[_c('button',{staticClass:"flex items-end w-full mb-4",on:{"click":function($event){return _vm.exportCsv(_vm.participe.publipostage.uuid, _vm.participe.agence.uuid)}}},[_c('img',{staticClass:"h-6 w-6 mr-1",attrs:{"src":require("@/assets/ico/exporter.svg"),"alt":"Exporter"}}),_vm._v(" Télécharger la liste des adresses. ")]),_c('router-link',{attrs:{"to":{
          name: 'DeclareVente',
          params: {
            publipostageUuid: _vm.participe.publipostage.uuid,
            agenceUuid: _vm.participe.agence.uuid,
          },
        }}},[_c('button',{staticClass:"bg-indigo-900 text-white rounded-full py-2 px-6"},[_vm._v(" Déclarer les ventes ")])])],1):_vm._e(),(_vm.participe.isDone && _vm.participe.publipostage.status === 'ACTIF')?_c('div',{staticClass:"flex justify-center w-full mt-auto"},[_c('router-link',{attrs:{"to":{
          name: 'ListAdresse',
          params: {
            publipostageUuid: _vm.participe.publipostage.uuid,
            agenceUuid: _vm.participe.agence.uuid,
          },
        }}},[_c('button',{staticClass:"bg-blue-500 text-white rounded-full py-2 px-6"},[_vm._v(" Modifier ")])])],1):_vm._e(),(!_vm.participe.isDone && _vm.participe.publipostage.status === 'ACTIF')?_c('div',{staticClass:"flex justify-center mt-auto"},[_c('router-link',{attrs:{"to":{
          name: 'GeneratePublipostage',
          params: {
            publipostageUuid: _vm.participe.publipostage.uuid,
            agenceUuid: _vm.participe.agence.uuid,
          },
        }}},[_c('button',{staticClass:"bg-indigo-900 text-white rounded-full py-2 px-6"},[_vm._v(" Générer ")])])],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }