export default class PublipostageDto {
  constructor(nom, marque, dateMiseEnLigne, dateLimite) {
    this.nom = nom;
    this.marque = marque;
    this.dateMiseEnLigne = dateMiseEnLigne;
    this.dateLimite = dateLimite;
  }

  getNom() {
    return this.nom;
  }

  getMarque() {
    return this.marque;
  }

  getDateMiseEnLigne() {
    return this.dateMiseEnLigne;
  }

  getDateLimite() {
    return this.dateLimite;
  }
}
