<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 67.8 67.7"
    style="enable-background: new 0 0 67.8 67.7"
    xml:space="preserve"
  >
    <g>
      <circle
        cx="33.9"
        cy="33.8"
        r="33.5"
        style="fill: currentColor; opacity: 0.12"
      />
      <circle cx="33.9" cy="33.8" r="33" style="fill: none; stroke: #fff" />
    </g>
    <path
      d="m809.5 282.5-11.4-11.4c-1-1-2.6-1-3.6 0l-11.4 11.4c-1 1-1 2.6 0 3.6.5.5 1.1.7 1.7.8h.6v8.4c0 1.7 1.3 3 3 3h4.5c.5 0 .8-.4.8-.8v-6.6c0-.8.6-1.4 1.4-1.4h2.6c.8 0 1.4.6 1.4 1.4v6.6c0 .5.4.8.8.8h4.5c1.7 0 3-1.4 3-3v-8.4h.4c1.4 0 2.6-1.1 2.6-2.6-.1-.7-.4-1.3-.9-1.8z"
      style="fill: currentColor"
      transform="translate(-762 -250)"
    />
  </svg>
</template>

<script>
export default {
  name: "AgenceIcon",
};
</script>
