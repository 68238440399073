<template>
  <div class="inline-flex shadow-lg border border-gray-200 rounded-2xl">
    <div class="flex items-center pl-4 py-2">
      <img
        src="@/assets/ico/barre-parametres.svg"
        alt="icone des paramètres"
        class="w-5 lg:w-8"
      />
    </div>
    <component
      :is="filter.type"
      v-for="(filter, index) in filters"
      :key="index"
      :class="buildClasses(index)"
      :value="getValue(filter.fields)"
      :fields="filter.fields"
      v-bind="filter.props"
      @input="handleChange"
    />
  </div>
</template>

<script>
import { FILTER_TYPES } from "@/components/filters/filterTypes";

export default {
  name: "FilterBar",
  components: {
    [FILTER_TYPES.MONTH_YEAR_TYPE]: () =>
      import("@/components/filters/MonthYearFilter"),
    [FILTER_TYPES.AGENCE_TYPE]: () =>
      import("@/components/filters/AgenceFilter"),
    [FILTER_TYPES.MARQUE_TYPE]: () =>
      import("@/components/filters/MarqueFilter"),
    [FILTER_TYPES.STATUS_TYPE]: () =>
      import("@/components/filters/StatusFilter"),
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    config: {
      type: Array,
      required: true,
    },
    storageKey: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    loaded: false,
  }),
  computed: {
    filters() {
      return this.loaded ? this.config : [];
    },
    filtersLength() {
      return this.config.length;
    },
  },
  created() {
    const data = sessionStorage.getItem(this.storageKey);
    if (data) {
      this.updateValue(JSON.parse(data), false);
    }
  },
  mounted() {
    this.loaded = true;
  },
  methods: {
    buildClasses(index) {
      let classes = ["flex", "items-center", "px-4", "py-2"];

      if (index !== this.filtersLength - 1) {
        classes.push("border-r", "border-gray-200");
      }

      return classes;
    },

    getValue(fields) {
      if (fields.length === 1) {
        return this.value[fields[0]];
      }

      return fields.reduce((acc, current) => {
        acc[current] = this.value[current];
        return acc;
      }, {});
    },

    updateValue(value, saveInSessionStorage = true) {
      const clone = Object.assign(
        Object.create(Object.getPrototypeOf(this.value)),
        this.value,
        value
      );
      this.$emit("input", clone);
      if (saveInSessionStorage) {
        sessionStorage.setItem(this.storageKey, JSON.stringify(clone));
      }
    },

    handleChange({ field, value }) {
      const data = { [field]: value };
      this.updateValue(data);
    },
  },
};
</script>
