<template>
  <div>
    <div class="h-16 flex items-center">
      <h1
        class="text-base lg:text-xl mr-auto"
        style="font-family: 'Poppins-SemiBold', 'sans-serif'"
      >
        Gestion des utilisateurs
      </h1>
      <div class="hidden lg:flex mx-auto justify-center items-center">
        <SecondaryNavItem
          v-for="marque in marques"
          :key="marque.uuid"
          :link="marque.code"
          :current-value="value"
          no-router
          v-on="$listeners"
        >
          {{ marque.nom }}
        </SecondaryNavItem>
        <SecondaryNavItem
          link="admin"
          :current-value="value"
          no-router
          v-on="$listeners"
        >
          Admin
        </SecondaryNavItem>
      </div>
      <BaseModal
        class="ml-auto mr-4"
        modal-size="lg:max-w-3xl"
        @validate="callValidate"
      >
        <template v-slot:button-content>
          <img
            src="@/assets/ico/plus.svg"
            alt="Ajouter un publipostage."
            class="w-7 max-w-lg"
          />
          <p class="ml-4">Créer un utilisateur</p>
        </template>
        <template v-slot:title>
          <h3 class="text-lg lg:text-xl font-semibold">
            Création d'un utilisateur
          </h3>
        </template>
        <template v-slot:content>
          <UserForm ref="validate" />
        </template>
      </BaseModal>
      <slot name="header-buttons" />
    </div>
    <div class="flex lg:hidden justify-center items-center my-11">
      <SecondaryNavItem
        v-for="marque in marques"
        :key="marque.uuid"
        :link="marque.code"
        :current-value="value"
        no-router
        v-on="$listeners"
      >
        {{ marque.nom }}
      </SecondaryNavItem>
      <SecondaryNavItem
        link="admin"
        :current-value="value"
        no-router
        v-on="$listeners"
      >
        Admin
      </SecondaryNavItem>
    </div>
  </div>
</template>

<script>
import SecondaryNavItem from "@/components/utils/menu/SecondaryNavItem";
import BaseModal from "@/components/modals/baseModal";
import UserForm from "@/components/forms/user/UserForm";
import { mapState } from "vuex";

export default {
  name: "UtilisateurMenu",
  components: {
    SecondaryNavItem,
    BaseModal,
    UserForm,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  data: () => ({}),
  computed: {
    ...mapState({
      marques: (state) => state.marques.marques,
    }),
  },
  methods: {
    callValidate() {
      this.$refs.validate.handleCreateSubmit();
    },
  },
};
</script>

<style lang="scss" scoped></style>
