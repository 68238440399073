import { userService } from "@/services";
import router from "@/router";
import AgenceFilter from "@/model/AgenceFilter";

export const users = {
  namespaced: true,
  state: {
    currentUser: JSON.parse(localStorage.getItem("user")),
    users: {
      admin: {},
      technitoit: {},
      europe_energie: {},
    },
  },
  actions: {
    getCurrentUser({ commit }) {
      commit("getCurrentUser");
    },
    async createUser({ dispatch }, { user }) {
      dispatch("loading/startLoad", "", { root: true });
      await userService
        .createUser(user)
        .then(() => {
          router.go();
        })
        .finally(() => {
          dispatch("loading/stopLoad", "", { root: true });
        });
    },
    async updateUser({ dispatch }, { user, userUuid }) {
      dispatch("loading/startLoad", "", { root: true });
      await userService
        .updateUser(user, userUuid)
        .then(() => {
          router.go();
        })
        .finally(() => {
          dispatch("loading/stopLoad", "", { root: true });
        });
    },
    deleteUser({ dispatch }, { userUuid }) {
      dispatch("loading/startLoad", "", { root: true });
      userService
        .deleteUser(userUuid)
        .then(() => {
          router.go();
        })
        .finally(() => {
          dispatch("loading/stopLoad", "", { root: true });
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    async getUsers({ rootState, commit, dispatch }, { page, usersFilter }) {
      await dispatch(
        { type: "agences/getAgences", agenceFilter: new AgenceFilter() },
        { root: true }
      );

      for (const marqueKey in rootState.marques.marques) {
        const marque = rootState.marques.marques[marqueKey];
        if (marque.code === page) {
          usersFilter.addSearchMarques(marque.uuid);
        }
      }
      let pagination = 1;
      let arrayUsers = [];
      let total = 0;

      do {
        await userService.getUsers(usersFilter, pagination).then((users) => {
          total = users.pages;
          pagination++;
          arrayUsers.push(...users.results);
        });
      } while (total >= pagination);

      commit({ type: "setUsers", user: arrayUsers, page: page });
      dispatch("loading/stopLoad", "", { root: true });
    },
  },
  mutations: {
    getCurrentUser(state) {
      state.currentUser = JSON.parse(localStorage.getItem("user"));
    },
    setUsers(state, { user, page }) {
      state.users[page] = user;
    },
  },
};
