import config from "@/config";
import router from "@/router";
import { authHeader, jsonStringify, refreshTokenBody } from "@/helpers";
import jwt_decode from "jwt-decode";

export const userService = {
  login,
  logout,
  requestPassword,
  resetPassword,
  createUser,
  updateUser,
  getUsers,
  deleteUser,
  loginWithRefreshToken,
};

function login(username, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username, password }),
  };

  return fetchResponse(`${config.apiUrl}/api/login_check`, requestOptions).then(
    (user) => {
      if (user.token && user.refresh_token) {
        saveCredentials(user);
      }

      return user;
    }
  );
}

function loginWithRefreshToken() {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(refreshTokenBody()),
  };

  return fetch(`${config.apiUrl}/api/token/refresh`, requestOptions)
    .then((Response) => {
      Response.text().then((response) => {
        const data = response && JSON.parse(response);
        if (!response.ok) {
          logout();
        }
        if (data.token && data.refresh_token) {
          saveCredentials(data);
        }

        router.go();
      });
    })
    .catch((error) => {
      console.log(error);
    });
}

function logout() {
  sessionStorage.clear();
  localStorage.removeItem("user");
}

function requestPassword(email) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email }),
  };

  return fetchResponse(`${config.apiUrl}/api/password/request`, requestOptions);
}

function resetPassword(token, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ token, password }),
  };

  return fetchResponse(`${config.apiUrl}/api/password/reset`, requestOptions);
}

function createUser(user) {
  const requestOptions = {
    method: "POST",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
    body: jsonStringify(user),
  };

  return fetchResponse(`${config.apiUrl}/api/utilisateurs`, requestOptions);
}

function updateUser(user, userUuid) {
  const requestOptions = {
    method: "PUT",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
    body: jsonStringify(user),
  };

  return fetchResponse(
    `${config.apiUrl}/api/utilisateurs/${userUuid}`,
    requestOptions
  );
}

function deleteUser(userUuid) {
  const requestOptions = {
    method: "DELETE",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
  };

  return fetchResponse(
    `${config.apiUrl}/api/utilisateurs/${userUuid}`,
    requestOptions
  );
}

function getUsers(userModel, page) {
  return fetchResponse(
    `${
      config.apiUrl
    }/api/utilisateurs?${userModel.sanitarizeQueryParam()}&page=${page}`,
    getDefaultOption("GET", authHeader())
  );
}

function handleResponse(response) {
  return response
    .text()
    .then((text) => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if (response.status === 401) {
          const user = JSON.parse(localStorage.getItem("user"));
          if (user && user.refreshToken) {
            loginWithRefreshToken().then();
          }

          const error = "Nom d'utilisateur ou mot de passe invalide.";
          return Promise.reject(error);
        }

        if (response.status === 400) {
          return Promise.reject(data);
        }

        const error =
          "Une erreur inconnue est survenu, veuillez réessayer plus tard, si le problème persiste, contacter un administrateur ";
        return Promise.reject(error);
      }

      return data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function saveCredentials(response) {
  const user = jwt_decode(response.token);

  localStorage.setItem(
    "user",
    JSON.stringify({
      refreshToken: response.refresh_token,
      token: response.token,
      name: user.name,
      role: user.roles,
      color: user.color,
      initials: user.initials,
    })
  );
}

function fetchResponse(uri, requestOptions) {
  return fetch(uri, requestOptions)
    .catch((error) => {
      error =
        "Une erreur inconnue est survenu, veuillez réessayer plus tard, si le problème persiste, contacter un administrateur ";
      return Promise.reject(error);
    })
    .then(handleResponse);
}

function getDefaultOption(method, auth, body = {}) {
  let requestOption = {
    headers: { "Content-Type": "application/json" },
    method: method,
  };

  if (method !== "GET") {
    requestOption.body = JSON.stringify(body);
  }

  if (auth) {
    Object.assign(requestOption.headers, auth);
  }
  return requestOption;
}
