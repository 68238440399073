<template>
  <div>
    <Error :title="errors.commonError" />
    <form class="flex flex-wrap">
      <div class="w-full mb-6">
        <label
          for="nom"
          class="px-1 mb-2"
          style="font-family: 'Poppins-SemiBold', 'sans-serif'"
          >Nom du publipostage</label
        >
        <input
          id="nom"
          v-model="publipostage.nom"
          name="nom"
          type="text"
          class="form-input"
          required
        />
        <Error :title="errors.nom" />
      </div>
      <div class="w-full mb-6">
        <Select
          :data="brandsOptions"
          :value="publipostage.marque.value"
          @valueSelect="onValueSelect"
        >
          <template v-slot:SelectLabel> Marques </template>
        </Select>
        <Error :title="errors.marque" />
      </div>
      <div class="w-full mb-6">
        <label
          for="dateMiseEnLigne"
          class="px-1 mb-2"
          style="font-family: 'Poppins-SemiBold', 'sans-serif'"
          >Date de mise en ligne</label
        >
        <datetime
          id="dateMiseEnLigne"
          v-model="publipostage.dateMiseEnLigne"
          class="border border-gray-200 rounded-full pl-6 mt-1 pr-3 py-4 w-full"
          input-class="w-full"
          type="datetime"
          :format="{
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: '2-digit',
          }"
          :phrases="{ ok: 'Ok', cancel: 'Annuler' }"
        />
        <Error :title="errors.dateMiseEnLigne" />
      </div>
      <div class="w-full mb-6">
        <label
          for="dateLimite"
          class="px-1 mb-2"
          style="font-family: 'Poppins-SemiBold', 'sans-serif'"
          >Date limite</label
        >
        <datetime
          id="dateLimite"
          v-model="publipostage.dateLimite"
          class="border border-gray-200 rounded-full pl-6 mt-1 pr-3 py-4 w-full"
          input-class="w-full"
          type="datetime"
          :format="{
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: '2-digit',
          }"
          :phrases="{ ok: 'Ok', cancel: 'Annuler' }"
        />
        <Error :title="errors.dateLimite" />
      </div>
    </form>
  </div>
</template>

<script>
import PublipostageModel from "@/model/Publipostage";
import Select from "@/components/forms/utils/Select";
import Error from "@/components/forms/utils/Error";
import { catchErrors } from "@/helpers";
import { mapState } from "vuex";

export default {
  name: "PublipostageForm",
  components: {
    Select,
    Error,
  },
  props: {
    publipostage: {
      type: Object,
      default: function () {
        return new PublipostageModel();
      },
    },
    publipostageUuid: {
      type: String,
      default: null,
    },
    publipostageFilter: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      errors: {
        commonError: null,
        nom: null,
        marque: null,
        dateLimite: null,
        dateMiseEnLigne: null,
      },
    };
  },
  computed: {
    ...mapState({
      marques: (state) => state.marques.marques,
    }),
    brandsOptions() {
      return this.marques.map((marque) => ({
        key: marque.code,
        value: marque.nom,
      }));
    },
  },
  methods: {
    handleEditSubmit() {
      this.resetErrorsMessage();
      const { dispatch } = this.$store;
      const publipostage = this.publipostage;

      if (this.publipostageUuid) {
        dispatch("publipostages/updatePublipostage", {
          publipostageModel: publipostage,
          publipostageUuid: this.publipostageUuid,
          publipostageFilter: this.publipostageFilter,
        })
          .then(() => {
            this.$emit("closeEditModal");
          })
          .catch((errors) => catchErrors(errors, this.errors));
      }
    },
    handleCreateSubmit() {
      this.resetErrorsMessage();
      const { dispatch } = this.$store;
      const publipostage = this.publipostage;

      dispatch("publipostages/createPublipostage", {
        publipostageModel: publipostage,
        publipostageFilter: this.publipostageFilter,
      })
        .then(() => {
          this.$emit("closeCreateModal");
        })
        .catch((errors) => catchErrors(errors, this.errors));
    },
    onValueSelect(value) {
      this.publipostage.marque = value;
    },
    resetErrorsMessage() {
      Object.keys(this.errors).forEach((k) => (this.errors[k] = null));
    },
  },
};
</script>

<style lang="scss" scoped>
.vdatetime-input {
  width: inherit;
}
</style>
