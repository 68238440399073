<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 41.1 41.4"
    xml:space="preserve"
  >
    <g>
      <path
        d="M9.5.6h22c5 0 9 4 9 9v22c0 5-4 9-9 9h-22c-5 0-9-4-9-9v-22c0-4.9 4-9 9-9z"
        fill="#fff"
      />
    </g>
    <g transform="translate(16.961 15.243)">
      <g>
        <path
          fill="currentColor"
          d="M.1 12v4.6c0 .3.2.6.5.7h.2c.2 0 .5-.1.6-.3l2.7-3.6-4-1.4z"
        />
        <path
          fill="currentColor"
          d="M14.9-5.2c-.2-.2-.5-.2-.8 0L-8.1 6.3c-.2.1-.4.4-.4.7 0 .3.2.5.5.6l6.2 2.1L11.3-1.5 1.2 10.8l10.3 3.5h.2c.1 0 .3 0 .4-.1.2-.1.3-.3.3-.5l2.7-18.2c.1-.3 0-.6-.2-.7z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "PublipostageIcon",
};
</script>
