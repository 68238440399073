import config from "@/config";
import {
  authHeader,
  fetchResponse,
  getDefaultOption,
  jsonStringify,
} from "@/helpers";

export const agenceService = {
  getAgences,
  createAgence,
  updateAgence,
  deleteAgence,
};

function getAgences(agenceFilter, page) {
  return fetchResponse(
    `${
      config.apiUrl
    }/api/agences?${agenceFilter.sanitarizeQueryParam()}&page=${page}`,
    getDefaultOption("GET", authHeader())
  );
}

function createAgence(agence) {
  const requestOptions = {
    method: "POST",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
    body: jsonStringify(agence),
  };

  return fetchResponse(`${config.apiUrl}/api/agences`, requestOptions);
}

function updateAgence(agence, agenceUuid) {
  const requestOptions = {
    method: "PUT",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
    body: jsonStringify(agence),
  };

  return fetchResponse(
    `${config.apiUrl}/api/agences/${agenceUuid}`,
    requestOptions
  );
}

function deleteAgence(agenceUuid) {
  const requestOptions = {
    method: "DELETE",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
  };

  return fetchResponse(
    `${config.apiUrl}/api/agences/${agenceUuid}`,
    requestOptions
  );
}
