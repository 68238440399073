export default class AgenceModel {
  nom = null;
  responsableTelephone = null;
  responsableNom = null;
  adresse = null;
  codePostal = null;
  ville = null;
  nbResultatsMax = null;
  marque = null;
  defaultParticipate = null;

  constructor(
    nom,
    responsableTelephone,
    responsableNom,
    adresse,
    codePostal,
    ville,
    nbResultatsMax,
    marque,
    defaultParticipate
  ) {
    this.nom = nom;
    this.responsableTelephone = responsableTelephone;
    this.responsableNom = responsableNom;
    this.adresse = adresse;
    this.codePostal = codePostal;
    this.ville = ville;
    this.nbResultatsMax = parseInt(nbResultatsMax);
    this.marque = marque;
    this.defaultParticipate = defaultParticipate;
  }

  get nom() {
    return this.nom;
  }

  get responsableTelephone() {
    return this.responsableTelephone;
  }

  get responsableNom() {
    return this.responsableNom;
  }

  get adresse() {
    return this.adresse;
  }

  get codePostal() {
    return this.codePostal;
  }

  get ville() {
    return this.ville;
  }

  get nbResultatsMax() {
    return this.nbResultatsMax;
  }

  get marque() {
    return this.marque;
  }

  get defaultParticipate() {
    return this.defaultParticipate;
  }
}
