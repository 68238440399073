import Vue from "vue";
import VueRouter from "vue-router";
import Publipostages from "../views/publipostage/Publipostages.vue";
import Blacklist from "../views/blacklist/Blacklist.vue";
import Reporting from "../views/reporting/Reporting.vue";
import GeneratePublipostage from "../views/publipostage/generate/Generate.vue";
import Utilisateurs from "../views/utilisateur/Utilisateurs.vue";
import Login from "../views/auth/Login.vue";
import RequestPassword from "../views/auth/RequestPassword.vue";
import ResetPassword from "../views/auth/ResetPassword.vue";
import Agences from "@/views/agence/Agences";
import { isAdmin } from "@/helpers/user";
import ListAdresses from "@/views/publipostage/adresse/ListAdresses";
import DeclareVente from "@/views/publipostage/adresse/DeclareVente";

Vue.use(VueRouter);

const routes = [
  {
    path: "/publipostages",
    component: Publipostages,
    name: "Publipostages",
    meta: {
      title: "Publipostage",
      menu: "Publipostages",
    },
  },
  {
    path: "/blacklist",
    component: Blacklist,
    name: "Blacklist",
    meta: {
      title: "Blacklist",
      menu: "Blacklist",
    },
  },
  {
    path: "/reporting",
    component: Reporting,
    name: "Reporting",
    meta: {
      title: "Reporting",
      menu: "Reporting",
    },
  },
  {
    path: "/publipostages/:publipostageUuid/agences/:agenceUuid",
    component: GeneratePublipostage,
    name: "GeneratePublipostage",
    meta: {
      title: "Générer le publipostage",
      menu: "Publipostages",
    },
  },
  {
    path: "/publipostages/:publipostageUuid/agences/:agenceUuid/adresses",
    component: ListAdresses,
    name: "ListAdresse",
    meta: {
      title: "Liste des adresses",
      menu: "Publipostages",
    },
  },
  {
    path: "/publipostages/:publipostageUuid/agences/:agenceUuid/declarer-vente",
    component: DeclareVente,
    name: "DeclareVente",
    meta: {
      title: "Déclarer des ventes",
      menu: "Publipostages",
    },
  },
  {
    path: "/utilisateurs",
    component: Utilisateurs,
    name: "Utilisateurs",
    meta: {
      title: "Utilisateurs - Publipostage",
      requiredPermission: "ADMIN",
      menu: "Utilisateurs",
    },
  },
  {
    path: "/agences",
    component: Agences,
    name: "Agences",
    meta: {
      title: "Agences - Publipostage",
      menu: "Agences",
    },
  },
  {
    path: "/login",
    component: Login,
    name: "Login",
    meta: {
      title: "Login - Publipostage",
    },
  },
  {
    path: "/password/request",
    component: RequestPassword,
    name: "RequestPassword",
    meta: {
      title: "Mot de passe oublié - Publipostage",
    },
  },
  {
    path: "/password/reset/:token",
    component: ResetPassword,
    name: "ResetPassword",
    meta: {
      title: "Nouveau mot de passe - Publipostage",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ["Login", "RequestPassword", "ResetPassword"];
  const authRequired = !publicPages.includes(to.name);
  const loggedIn = localStorage.getItem("user");

  if (authRequired && !loggedIn) {
    return next("/login");
  }

  if (to && to.name === null) {
    return next("/publipostages");
  }

  if (to.path === "/login" && loggedIn) {
    return next("/publipostages");
  }

  if (
    to.meta.requiredPermission &&
    to.meta.requiredPermission === "ADMIN" &&
    !isAdmin()
  ) {
    return next("/publipostages");
  }

  if (to && to.matched) {
    const nearestWithTitle = to.matched
      .slice()
      .reverse()
      .find((r) => r.meta && r.meta.title);
    if (nearestWithTitle) document.title = nearestWithTitle.meta.title;
  }

  next();
});

export default router;
