<template>
  <div id="app">
    <div
      v-if="loading"
      id="loader"
      class="
        min-h-screen min-w-full
        flex
        items-center
        justify-center
        fixed
        top-0
        left-0
        bg-white
        opacity-75
        z-50
      "
    >
      <propagate-loader
        class="custom-class"
        color="#FF3693"
        :loading="loading"
        size-unit="px"
      />
    </div>
    <div v-if="currentUser" class="ml-24 lg:ml-80">
      <!-- Static sidebar for desktop -->
      <Menu />
      <main>
        <router-view class="px-8 lg:px-10 py-3 lg:py-6">
          <template v-slot:header-buttons>
            <UserProfil />
          </template>
        </router-view>
      </main>
    </div>
    <template v-if="!currentUser">
      <router-view />
    </template>
  </div>
</template>

<script>
import { PropagateLoader } from "@saeris/vue-spinners";
import UserProfil from "@/components/utils/UserProfil";
import Menu from "@/components/utils/menu/Menu";

export default {
  name: "App",
  components: {
    PropagateLoader,
    UserProfil,
    Menu,
  },
  computed: {
    alert() {
      return this.$store.state.alert;
    },
    loading() {
      return this.$store.state.loading.isLoading;
    },
    currentUser() {
      return this.$store.state.users.currentUser;
    },
  },
  watch: {
    $route() {
      this.$store.dispatch("alert/clear");
      this.$store.dispatch("loading/stopLoad");
      this.$store.dispatch("users/getCurrentUser");
    },
  },
};
</script>
<style lang="scss" scoped>
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: url("~@/assets/fonts/poppins-v15-latin-regular.woff2") format("woff2"),
    url("~@/assets/fonts/poppins-v15-latin-regular.woff") format("woff");
}

@font-face {
  font-family: "Poppins-bold";
  font-style: normal;
  src: url("~@/assets/fonts/poppins-bold.woff2") format("woff2"),
    url("~@/assets/fonts/poppins-bold.woff") format("woff");
}

@font-face {
  font-family: "Poppins-SemiBold";
  font-style: normal;
  src: url("~@/assets/fonts/poppins-SemiBold.woff2") format("woff2"),
    url("~@/assets/fonts/poppins-SemiBold.woff") format("woff");
}

@font-face {
  font-family: "Poppins-Medium";
  font-style: normal;
  src: url("~@/assets/fonts/Poppins-Medium.woff2") format("woff2"),
    url("~@/assets/fonts/Poppins-Medium.woff") format("woff");
}

#app {
  font-family: "Poppins", sans-serif;
}
</style>
