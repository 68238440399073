import config from "@/config";
import { authHeader, fetchResponse, getDefaultOption } from "@/helpers";

export const marqueService = {
  getMarques,
};

function getMarques() {
  return fetchResponse(
    `${config.apiUrl}/api/marques`,
    getDefaultOption("GET", authHeader())
  );
}
