<template>
  <div>
    <div>
      <UtilisateurMenu v-model="currentGroup">
        <template v-slot:header-buttons>
          <slot name="header-buttons" />
        </template>
      </UtilisateurMenu>
    </div>
    <div class="lg:mt-11">
      <div class="border rounded-xl border-gray-200 p-4 h-full my-4">
        <div class="flex flex-wrap">
          <div class="w-full grid grid-cols-8 mt-3 mb-2 text-sm">
            <div class="flex pl-2 text-gray-300 focus-within:text-gray-400">
              <label for="searchNom">
                <font-awesome-icon icon="search" size="1x" class="mr-2" />
              </label>
              <input
                id="searchNom"
                v-model="nom"
                type="text"
                placeholder="Nom"
              />
            </div>
            <div class="flex pl-2 text-gray-300 focus-within:text-gray-400">
              <label for="searchPrenom">
                <font-awesome-icon icon="search" size="1x" class="mr-2" />
              </label>
              <input
                id="searchPrenom"
                v-model="prenom"
                type="text"
                placeholder="Prénom"
              />
            </div>
            <div
              class="
                flex
                pl-2
                text-gray-300
                focus-within:text-gray-400
                col-span-2
              "
            >
              <label for="searchEmail">
                <font-awesome-icon icon="search" size="1x" class="mr-2" />
              </label>
              <input
                id="searchEmail"
                v-model="email"
                type="text"
                placeholder="Email"
              />
            </div>
            <div style="color: #c9c9c9">Rôle</div>
            <div
              v-if="currentGroup !== 'admin'"
              class="col-span-3"
              style="color: #c9c9c9"
            >
              Agence
            </div>
          </div>
          <BaseModal
            v-for="user in filteredUsers"
            :key="user.uuid"
            button-size="w-full"
            button-class="text-left user w-full border rounded-xl border-gray-200 grid grid-cols-8 hover:shadow-md cursor-pointer mb-3"
            @validate="callValidate"
          >
            <template v-slot:button-content>
              <div class="nom h-full p-5">
                {{ user.nom }}
              </div>
              <div class="prenom h-full p-5 border-l border-gray-200">
                {{ user.prenom }}
              </div>
              <div
                class="
                  h-full
                  email
                  p-5
                  border-l border-gray-200
                  break-words
                  col-span-2
                "
              >
                {{ user.email }}
              </div>
              <div
                class="border-l border-gray-200 h-full p-5"
                :class="{ 'col-span-4': currentGroup === 'admin' }"
              >
                <span
                  v-for="role in getRolesToDisplay(user)"
                  v-show="role !== 'ROLE_USER'"
                  :key="role"
                  :class="{ 'admin-chip': role === 'ROLE_ADMIN' }"
                >
                  {{ displayRole(role) }}
                </span>
              </div>
              <div
                v-if="currentGroup !== 'admin'"
                class="
                  col-span-3
                  flex flex-wrap
                  justify-between
                  border-l border-gray-200
                  h-full
                "
              >
                <div class="w-11/12 px-4 py-2 flex flex-wrap">
                  <div
                    v-for="agence in user.agences"
                    :key="agence.uuid"
                    class="mb-1 mr-1"
                  >
                    <span
                      class="
                        block
                        text-center
                        rounded-full
                        px-4
                        py-1
                        text-xs
                        bg-opacity-20
                      "
                      :style="getAgenceColor(agence)"
                      >{{ agence.nom }}</span
                    >
                  </div>
                </div>
                <div
                  class="
                    bg-primary
                    w-5
                    h-full
                    rounded-r-xl
                    right
                    hidden
                    2xl:block
                  "
                />
              </div>
            </template>
            <template v-slot:title>
              <div
                class="
                  rounded-full
                  h-24
                  w-24
                  flex
                  items-center
                  justify-center
                  mx-auto
                "
                :class="'bg-' + user.color"
              >
                <h1 class="text-4xl text-white">
                  {{ user.initials }}
                </h1>
              </div>
              <BaseModal
                button-class="rounded-lg shadow-lg flex items-center justify-center p-3 absolute top-4 right-0"
                modal-size="md:max-w-md"
                @validate="removeUser(user.uuid)"
              >
                <template v-slot:button-content>
                  <img
                    src="@/assets/ico/trash.svg"
                    alt="Logo du publipostage"
                    class="h-6 w-auto"
                  />
                </template>
                <template v-slot:title>
                  <div
                    class="flex flex-wrap items-center justify-center w-full"
                  >
                    <h2 class="text-lg font-bold">Supprimer</h2>
                  </div>
                </template>
                <template v-slot:content>
                  <div
                    class="flex flex-wrap items-center justify-center w-full"
                  >
                    <div class="text-center">
                      Êtes-vous sûr de vouloir supprimer cet utilisateur ?
                    </div>
                  </div>
                </template>
                <template v-slot:validation-content> Supprimer </template>
              </BaseModal>
            </template>
            <template v-slot:content>
              <UserForm
                ref="validate"
                :user-uuid="user.uuid"
                :users-roles="user.roles"
                :nom="user.nom"
                :prenom="user.prenom"
                :email="user.email"
                :users-agences="user.agences"
              />
            </template>
          </BaseModal>
          <div
            v-show="filteredUsers && filteredUsers.length === 0"
            class="
              flex
              justify-center
              items-center
              w-full
              border-t border-gray-200
              mt-2
            "
          >
            <div
              class="
                p-2
                bg-primary
                items-center
                text-gray-100
                leading-none
                lg:rounded-full
                flex
                lg:inline-flex
                mt-5
                cursor-pointer
              "
              role="alert"
              @click="initFilter"
            >
              <span class="px-2 py-1 font-semibold mr-2 text-left flex-auto"
                >Aucun utilisateur trouvé, réinitialiser vos filtres ?</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UtilisateurMenu from "@/components/utils/menu/UtilisateurMenu";
import { mapState } from "vuex";
import BaseModal from "@/components/modals/baseModal";
import UserForm from "@/components/forms/user/UserForm";
import { stringContains } from "@/utils/String";
import UsersFilter from "@/model/UsersFilter";
import { userService } from "@/services";
import { hex2rgba } from "@/utils/Colors";
import AgenceFilter from "@/model/AgenceFilter";

export default {
  name: "Utilisateurs",
  components: {
    UtilisateurMenu,
    BaseModal,
    UserForm,
  },
  data() {
    return {
      nom: "",
      prenom: "",
      email: "",
      currentGroup: null,
      users: [],
    };
  },
  computed: {
    ...mapState({
      marques: (state) => state.marques.marques,
    }),
    filteredUsers() {
      return this.users.filter((user) => {
        if (this.nom.length && !stringContains(user.nom, this.nom)) {
          return false;
        }
        if (this.prenom.length && !stringContains(user.prenom, this.prenom)) {
          return false;
        }
        if (this.email.length && !stringContains(user.email, this.email)) {
          return false;
        }
        return true;
      });
    },
    currentBrand() {
      if (!this.currentGroup || this.currentGroup === "admin") {
        return null;
      }

      return this.marques.find((marque) => marque.code === this.currentGroup);
    },
  },
  watch: {
    currentGroup() {
      if (!this.currentGroup) {
        return;
      }
      this.getUsers();
    },
  },
  created() {
    this.getBrands();
    this.$store.dispatch({
      type: "agences/getAgences",
      agenceFilter: new AgenceFilter(),
    });
  },
  methods: {
    async getBrands() {
      await this.$store.dispatch({ type: "marques/getMarques" });
      this.currentGroup = this.marques[0]?.code;
    },
    async getUsers() {
      const filter = new UsersFilter();
      if (this.currentGroup === "admin") {
        filter._searchRoles = ["admin"];
      } else {
        filter._searchMarques = [this.currentBrand.uuid];
      }
      let page = 1;
      let items = [];
      let totalPages = 0;

      do {
        const users = await userService.getUsers(filter, page);
        totalPages = users.pages;
        page++;
        items.push(...users.results);
      } while (page <= totalPages);

      this.users = items;
    },
    callValidate() {
      this.$refs.validate[0].handleEditSubmit();
    },
    initFilter() {
      this.nom = "";
      this.prenom = "";
      this.email = "";
    },
    removeUser(userUuid) {
      const { dispatch } = this.$store;

      if (userUuid) {
        dispatch("users/deleteUser", { userUuid });
      }
    },
    displayRole: function (role) {
      switch (role) {
        case "ROLE_SECRETAIRE":
          return "Secrétaire";
        case "ROLE_DA":
          return "Directeur d'agence";
        case "ROLE_DZ":
          return "Directeur de zone";
        case "ROLE_ADMIN":
          return "Admnistrateur";
        default:
          return role;
      }
    },
    getAgenceColor(agence) {
      const color = agence.marque.couleur;
      return {
        color,
        backgroundColor: hex2rgba(color, 0.2),
      };
    },
    getRolesToDisplay(user) {
      return user.roles.filter((role) => role !== "ROLE_USER");
    },
  },
};
</script>

<style lang="scss" scoped>
.admin-chip {
  @apply text-center rounded-full text-white px-4 py-1 text-xs bg-purple-200 text-purple-700 break-words;
}
</style>
