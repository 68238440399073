<template>
  <div>
    <label
      id="listbox-label"
      class="px-1 mb-2 text-sm"
      style="font-family: 'Poppins-Medium', 'sans-serif'"
    >
      <slot name="SelectLabel" />
    </label>
    <div v-click-outside="closeDropdown" class="relative pt-0">
      <span class="inline-block w-full rounded-md">
        <button
          ref="button"
          type="button"
          aria-haspopup="listbox"
          aria-expanded="true"
          aria-labelledby="listbox-label"
          class="
            cursor-pointer
            relative
            w-full
            pl-6
            pr-3
            py-4
            mt-1
            rounded-full
            border border-gray-200
            outline-none
            focus:border-primary
            text-left
            transition
            ease-in-out
            duration-150
            sm:leading-5
          "
          @click="toggleDropdown"
        >
          <div class="flex items-center space-x-3">
            <span class="block truncate">
              {{ value }}
            </span>
          </div>
          <span
            class="
              absolute
              inset-y-0
              right-2
              flex
              items-center
              pr-2
              pointer-events-none
            "
          >
            <span class="ml-4"
              ><font-awesome-icon
                icon="chevron-right"
                :class="[isOpen ? 'go' : 'aa']"
            /></span>
          </span>
        </button>
      </span>
      <div
        v-if="isOpen"
        ref="options"
        class="rounded-lg border border-primary bg-white z-10"
      >
        <ul
          tabindex="-1"
          role="listbox"
          aria-labelledby="listbox-label"
          aria-activedescendant="listbox-item-3"
          class="
            max-h-80
            rounded-md
            text-base
            leading-6
            shadow-xs
            overflow-auto
            focus:outline-none
            sm:leading-5
          "
        >
          <li
            v-for="d in data"
            id="listbox-item-0"
            :key="d.key"
            tabindex="0"
            role="option"
            class="
              text-gray-800
              cursor-default
              select-none
              relative
              py-3
              pl-3
              pr-9
              border-b border-gray-200
              text-sm
              cursor-pointer
              hover:text-white hover:bg-primary
              focus:outline-none focus:text-white
            "
            @click="select(d)"
          >
            <div class="flex items-center space-x-3">
              {{ d.value }}
            </div>
            <span
              v-show="isSelected(d.value)"
              class="absolute inset-y-0 right-0 flex items-center pr-4"
            >
              <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path
                  fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import { createPopper } from "@popperjs/core";
import { sameWidth } from "@/helpers/popper-modifiers";

export default {
  name: "Select",
  directives: {
    ClickOutside,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    value: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isOpen: false,
      popper: null,
    };
  },
  watch: {
    isOpen(isOpen) {
      if (!isOpen) {
        return;
      }
      this.$nextTick(() => {
        this.initPopper();
      });
    },
  },
  methods: {
    isSelected(value) {
      return this.value === value;
    },
    closeDropdown() {
      this.isOpen = false;
    },
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    select(value) {
      this.isOpen = false;
      this.$emit("valueSelect", value);
    },
    initPopper() {
      createPopper(this.$refs.button, this.$refs.options, {
        placement: "bottom-start",
        strategy: "fixed",
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 8],
            },
          },
          sameWidth,
        ],
      });
    },
  },
};
</script>

<style scoped>
.aa {
  transition: all 0.5s;
}

.go {
  transform: rotate(90deg);
  transition: all 0.5s;
}
</style>
