<template>
  <div v-if="isLoaded">
    <AgenceMenu
      v-model="currentBrandCode"
      :has-multiple-brands="hasMultipleBrands"
    >
      <template v-slot:header-buttons>
        <slot name="header-buttons" />
      </template>
    </AgenceMenu>
    <div class="lg:mt-11">
      <div class="flex items-center mb-7 form-search w-2/5">
        <font-awesome-icon
          icon="search"
          size="1x"
          class="mr-2 text-gray-600 mr-4"
          @click="search"
        />
        <input
          id="searchKeyword"
          v-model="filter._searchKeyword"
          name="searchKeyword"
          type="text"
          class="flex-grow mr-2 max-w-3xl"
          placeholder="Nom, Adresse, Ville, Code postal, Nom du responsable"
          @keyup.enter="search"
          @input="handleSearchInput"
        />
      </div>
      <div
        v-if="!displayedAgences.length"
        class="flex justify-center items-center w-full mt-2"
      >
        <div
          class="
            p-2
            bg-primary
            items-center
            text-gray-100
            leading-none
            lg:rounded-full
            flex
            lg:inline-flex
            mt-5
            cursor-pointer
          "
          role="alert"
          @click="resetFilter"
        >
          <span class="px-2 py-1 font-semibold mr-2 text-left flex-auto"
            >Aucune agence trouvée, réinitialiser vos filtres ?</span
          >
        </div>
      </div>
      <div class="grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-6">
        <template v-if="isAdmin">
          <BaseModal
            v-for="(agence, index) in displayedAgences"
            :key="agence.uuid"
            ref="modal"
            button-class="w-full h-full"
            @validate="callValidate"
          >
            <template v-slot:button-content>
              <AgenceCard :agence="agence" :brand="currentBrand" />
            </template>
            <template v-slot:title>
              <div class="flex items-center">
                <AgenceIcon
                  class="h-16 w-auto mr-6"
                  :style="{ color: currentBrand.couleur }"
                />
                <h3 class="text-lg lg:text-xl font-semibold">
                  {{ agence.nom }}
                </h3>
                <div class="flex ml-auto">
                  <BaseModal
                    button-class="hover:bg-gray-100 rounded-lg shadow-md flex items-center justify-center p-3"
                    modal-size="md:max-w-md"
                    @validate="removeAgence(agence.uuid)"
                  >
                    <template v-slot:button-content>
                      <img
                        src="@/assets/ico/trash.svg"
                        alt="Bouton de suppression de cette agence"
                        class="h-6 w-auto"
                      />
                    </template>
                    <template v-slot:title>
                      <div
                        class="
                          flex flex-wrap
                          items-center
                          justify-center
                          w-full
                        "
                      >
                        <h2 class="text-lg font-bold">Supprimer</h2>
                      </div>
                    </template>
                    <template v-slot:content>
                      <div
                        class="
                          flex flex-wrap
                          items-center
                          justify-center
                          w-full
                        "
                      >
                        <div class="text-center">
                          Êtes-vous sûr de vouloir supprimer cette agence ?
                        </div>
                      </div>
                    </template>
                    <template v-slot:validation-content> Supprimer </template>
                  </BaseModal>
                </div>
              </div>
            </template>
            <template v-slot:content>
              <AgenceForm
                ref="validate"
                :agence="agence"
                :agence-uuid="agence.uuid"
                @closeModal="closeModal(index)"
              />
            </template>
          </BaseModal>
        </template>
        <template v-else>
          <AgenceCard
            v-for="agence in displayedAgences"
            :key="agence.uuid"
            :agence="agence"
            :brand="currentBrandCode"
            no-hover
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import AgenceMenu from "@/components/utils/menu/AgenceMenu";
import AgenceFilter from "@/model/AgenceFilter";
import { mapState } from "vuex";
import { isAdmin } from "@/helpers/user";
import BaseModal from "@/components/modals/baseModal";
import AgenceForm from "@/components/forms/agence/AgenceForm";
import AgenceCard from "@/components/utils/agence/AgenceCard";
import AgenceIcon from "@/components/icons/AgenceIcon";
import { debounce } from "@/utils/debounce";

export default {
  name: "Agences",
  components: {
    AgenceMenu,
    BaseModal,
    AgenceForm,
    AgenceCard,
    AgenceIcon,
  },
  data() {
    return {
      filter: new AgenceFilter(),
      currentBrandCode: "",
      hasMultipleBrands: false,
      isAdmin: isAdmin(),
      isLoaded: false,
    };
  },
  computed: {
    currentBrand() {
      return this.marques.find(
        (marque) => marque.code === this.currentBrandCode
      );
    },

    displayedAgences() {
      return this.agences.filter(
        (agence) => agence.marque.code === this.currentBrandCode
      );
    },

    debounceSearch() {
      return debounce(() => {
        this.search();
      });
    },

    ...mapState({
      agences: (state) => state.agences.agences,
      marques: (state) => state.marques.marques,
    }),
  },
  created() {
    this.search().then(() => {
      this.hasMultipleBrands = this.isAdmin || this.marques.length;

      this.currentBrandCode = this.marques[0].code;

      this.isLoaded = true;
    });
  },
  methods: {
    callValidate() {
      this.$refs.validate[0].handleEditSubmit(this.filter);
    },

    search() {
      return this.$store.dispatch("agences/getAgences", {
        agenceFilter: this.filter,
      });
    },

    handleSearchInput() {
      this.debounceSearch();
    },

    resetFilter() {
      this.filter = new AgenceFilter("");
      this.search();
    },

    removeAgence(agenceUuid) {
      if (!agenceUuid) {
        return;
      }

      this.$store.dispatch("agences/deleteAgence", { agenceUuid });
    },

    closeModal(index) {
      this.$refs.modal[index].closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-success {
  background: transparent linear-gradient(268deg, #f5264c 0%, #e31bb1 100%) 0%
    0% no-repeat padding-box;
}
</style>
