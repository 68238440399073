export default class ParticipateGenerateModel {
  constructor(
    type,
    gender,
    nom,
    prenom,
    adresse,
    codePostal,
    ville,
    latitude,
    longitude
  ) {
    this.type = type;
    this.gender = gender;
    this.nom = nom;
    this.prenom = prenom;
    this.adresse = adresse;
    this.codePostal = codePostal;
    this.ville = ville;
    this.latitude = latitude;
    this.longitude = longitude;
  }

  getType() {
    return this.type;
  }

  getGender() {
    return this.gender;
  }

  getNom() {
    return this.nom;
  }

  getPrenom() {
    return this.prenom;
  }

  getAdresse() {
    return this.adresse;
  }

  getCodePostal() {
    return this.codePostal;
  }

  getVille() {
    return this.ville;
  }

  getLatitude() {
    return this.latitude;
  }

  getLongitude() {
    return this.longitude;
  }
}
