<template>
  <div class="flex flex-wrap w-full p-7 px-12 mt-5">
    <h1 class="text-2xl w-full">Générer un publipostage</h1>
    <div class="w-full flex justify-between items-center">
      <router-link to="/publipostages">
        <button class="mt-5 p-4 border border-gray-200 rounded-2xl shadow">
          <img
            src="@/assets/ico/retour-arrow.svg"
            alt="Retour en arrière"
            class="h-3 w-auto"
          />
        </button>
      </router-link>
      <router-link
        v-if="participe.isDone"
        :to="{
          name: 'ListAdresse',
          params: {
            publipostageUuid: participe.publipostage.uuid,
            agenceUuid: participe.agence.uuid,
          },
        }"
      >
        <button
          class="
            mt-5
            p-4
            border border-gray-200
            rounded-2xl
            shadow
            flex
            items-center
            text-secondary
          "
        >
          <p class="mr-2">Accéder à la liste des adresses</p>
          <img
            src="@/assets/ico/next-arrow.svg"
            alt="suivant"
            class="h-3 w-auto"
          />
        </button>
      </router-link>
    </div>
    <div v-if="Object.keys(participe).length > 0" class="flex mt-5 w-full">
      <div
        class="
          grid grid-cols-1
          lg:grid-cols-2
          w-full
          border border-gray-200
          rounded-2xl
          overflow-hidden
          z-10
        "
      >
        <div class="border-r-0 lg:border-r border-200">
          <div
            class="
              flex
              p-5
              justify-around
              items-center
              border-b border-gray-200
            "
          >
            <h2 class="text-xl">
              {{ participe.publipostage.nom }}
            </h2>
            <div class="flex">
              <img
                src="@/assets/ico/date.svg"
                alt="icone date"
                class="w-5 mr-2"
              />
              <p class="text-sm">
                {{ dateFormat(participe.publipostage.dateLimite) }}
              </p>
            </div>
          </div>
          <div class="grid grid-cols-2">
            <button
              class="w-full h-full p-5 border-b-2"
              :class="generateByChantier ? 'border-primary text-primary' : ''"
              @click="generateByChantier = true"
            >
              Par chantier
            </button>
            <button
              class="w-full h-full p-5 border-b-2"
              :class="!generateByChantier ? 'border-primary text-primary' : ''"
              @click="generateByChantier = false"
            >
              Par nom de rue
            </button>
          </div>
          <div class="flex w-full font-normal">
            <div v-if="generateByChantier" class="flex flex-wrap w-full p-7">
              <div class="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
                <div class="flex w-full flex-wrap justify-between col-span-2">
                  <div class="flex">
                    <div
                      class="flex items-center cursor-pointer"
                      @click="changeGender('woman')"
                    >
                      <span
                        class="
                          flex
                          justify-center
                          items-center
                          rounded-full
                          w-4
                          h-4
                          border border-gray-200
                          mr-2
                        "
                      >
                        <span
                          v-if="formChantier.gender === 'woman'"
                          class="rounded-full w-2 h-2 bg-primary"
                        />
                      </span>
                      <p>Madame</p>
                    </div>
                    <div
                      class="flex items-center ml-10 cursor-pointer"
                      @click="changeGender('man')"
                    >
                      <span
                        class="
                          flex
                          justify-center
                          items-center
                          rounded-full
                          w-4
                          h-4
                          border border-gray-200
                          mr-2
                        "
                      >
                        <span
                          v-if="formChantier.gender === 'man'"
                          class="rounded-full w-2 h-2 bg-primary"
                        />
                      </span>
                      <p>Monsieur</p>
                    </div>
                  </div>
                  <div class="flex">
                    <div v-if="formChantier.disableFields">
                      <button
                        class="
                          text-indigo-900
                          border border-gray-200
                          rounded-lg
                          p-2
                        "
                        @click="editAdressChantier()"
                      >
                        <font-awesome-icon icon="edit" size="1x" />
                        Modifier l'adresse
                      </button>
                    </div>
                  </div>
                </div>
                <div class="flex flex-wrap w-full mt-5 items-center">
                  <label for="formChantierNom" class="text-md text mb-2"
                    >Nom</label
                  >
                  <input
                    id="formChantierNom"
                    v-model="formChantier.nom"
                    name="formChantierNom"
                    type="text"
                    class="form-input"
                    :disabled="formChantier.disableFields"
                    :class="formChantier.disableFields ? 'bg-gray-200' : ''"
                    required
                  />
                </div>
                <div class="flex flex-wrap w-full mt-5 items-center">
                  <label for="formChantierPrenom" class="text-md text mb-2"
                    >Prénom</label
                  >
                  <input
                    id="formChantierPrenom"
                    v-model="formChantier.prenom"
                    name="formChantierPrenom"
                    type="text"
                    class="form-input"
                    :disabled="formChantier.disableFields"
                    :class="formChantier.disableFields ? 'bg-gray-200' : ''"
                    required
                  />
                </div>
                <div class="flex flex-wrap w-full mt-5 items-center col-span-2">
                  <label for="formChantierAdresse" class="text-md text mb-2"
                    >Adresse</label
                  >
                  <input
                    id="formChantierAdresse"
                    v-model="formChantier.adresse"
                    name="formChantierAdresse"
                    type="text"
                    class="form-input"
                    :disabled="formChantier.disableFields"
                    :class="formChantier.disableFields ? 'bg-gray-200' : ''"
                    required
                  />
                </div>
                <div class="flex flex-wrap w-full mt-5 items-center">
                  <label for="formChantierCodePostal" class="text-md text mb-2"
                    >Code postal</label
                  >
                  <input
                    id="formChantierCodePostal"
                    v-model="formChantier.codePostal"
                    name="formChantierCodePostal"
                    type="text"
                    class="form-input"
                    :disabled="formChantier.disableFields"
                    :class="formChantier.disableFields ? 'bg-gray-200' : ''"
                    required
                  />
                </div>
                <div class="flex flex-wrap w-full mt-5 items-center">
                  <label for="formChantierVille" class="text-md text mb-2"
                    >Ville</label
                  >
                  <input
                    id="formChantierVille"
                    v-model="formChantier.ville"
                    name="formChantierVille"
                    type="text"
                    class="form-input"
                    :disabled="formChantier.disableFields"
                    :class="formChantier.disableFields ? 'bg-gray-200' : ''"
                    required
                  />
                </div>
                <div
                  class="
                    w-full
                    mt-7
                    flex flex-wrap
                    justify-center
                    items-center
                    col-span-2
                  "
                >
                  <button
                    class="w-full rounded-full py-3 text-white"
                    :class="
                      formChantier.allowSubmit
                        ? 'bg-blue-900'
                        : 'bg-gray-400 cursor-not-allowed'
                    "
                    @click="handleSubmitAdressChantier()"
                  >
                    Visualiser l'adresse
                  </button>
                </div>
                <div
                  v-if="
                    Object.keys(formChantier.coordonnee).length !== 0 &&
                    formChantier.allowToContinue
                  "
                  class="
                    w-full
                    mt-3
                    flex flex-wrap
                    justify-center
                    items-center
                    col-span-2
                  "
                >
                  <button
                    class="w-full rounded-full py-3 text-white continueButton"
                    @click="handleSubmitChantierContinue()"
                  >
                    Générer ma liste
                  </button>
                  <div class="w-full flex items-center mt-2">
                    <svg
                      class="w-5 mr-2"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 496.158 496.158"
                      style="enable-background: new 0 0 496.158 496.158"
                      xml:space="preserve"
                    >
                      <path
                        style="fill: #2e5fff"
                        d="M496.158,248.085c0-137.022-111.069-248.082-248.075-248.082C111.07,0.003,0,111.063,0,248.085
	c0,137.001,111.07,248.07,248.083,248.07C385.089,496.155,496.158,385.086,496.158,248.085z"
                      />
                      <g>
                        <path
                          style="fill: #ffffff"
                          d="M315.249,359.555c-1.387-2.032-4.048-2.755-6.27-1.702c-24.582,11.637-52.482,23.94-57.958,25.015
		c-0.138-0.123-0.357-0.348-0.644-0.737c-0.742-1.005-1.103-2.318-1.103-4.015c0-13.905,10.495-56.205,31.192-125.719
		c17.451-58.406,19.469-70.499,19.469-74.514c0-6.198-2.373-11.435-6.865-15.146c-4.267-3.519-10.229-5.302-17.719-5.302
		c-12.459,0-26.899,4.73-44.146,14.461c-16.713,9.433-35.352,25.41-55.396,47.487c-1.569,1.729-1.733,4.314-0.395,6.228
		c1.34,1.915,3.825,2.644,5.986,1.764c7.037-2.872,42.402-17.359,47.557-20.597c4.221-2.646,7.875-3.989,10.861-3.989
		c0.107,0,0.199,0.004,0.276,0.01c0.036,0.198,0.07,0.5,0.07,0.933c0,3.047-0.627,6.654-1.856,10.703
		c-30.136,97.641-44.785,157.498-44.785,182.994c0,8.998,2.501,16.242,7.432,21.528c5.025,5.393,11.803,8.127,20.146,8.127
		c8.891,0,19.712-3.714,33.08-11.354c12.936-7.392,32.68-23.653,60.363-49.717C316.337,364.326,316.636,361.587,315.249,359.555z"
                        />
                        <path
                          style="fill: #ffffff"
                          d="M314.282,76.672c-4.925-5.041-11.227-7.597-18.729-7.597c-9.34,0-17.475,3.691-24.176,10.971
		c-6.594,7.16-9.938,15.946-9.938,26.113c0,8.033,2.463,14.69,7.32,19.785c4.922,5.172,11.139,7.794,18.476,7.794
		c8.958,0,17.049-3.898,24.047-11.586c6.876-7.553,10.363-16.433,10.363-26.393C321.646,88.105,319.169,81.684,314.282,76.672z"
                        />
                      </g>
                    </svg>
                    <p>Forfait minimum de 100€ par publipostage</p>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="p-7 flex w-full justify-center items-center">
              <div class="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
                <div class="flex w-full flex-wrap justify-end col-span-2">
                  <div class="flex">
                    <div v-if="formRue.disableFields">
                      <button
                        class="
                          text-indigo-900
                          border border-gray-200
                          rounded-lg
                          p-2
                        "
                        @click="editAdressRue()"
                      >
                        <font-awesome-icon icon="edit" size="1x" />
                        Modifier l'adresse
                      </button>
                    </div>
                  </div>
                </div>
                <div class="flex flex-wrap w-full items-center col-span-2">
                  <label for="formRueAdresse" class="text-md text mb-2"
                    >Adresse</label
                  >
                  <input
                    id="formRueAdresse"
                    v-model="formRue.adresse"
                    name="formRueAdresse"
                    type="text"
                    class="form-input"
                    :disabled="formRue.disableFields"
                    :class="formRue.disableFields ? 'bg-gray-200' : ''"
                    required
                  />
                </div>
                <div class="flex flex-wrap w-full mt-5 items-center">
                  <label for="formRueCodePostal" class="text-md text mb-2"
                    >Code postal</label
                  >
                  <input
                    id="formRueCodePostal"
                    v-model="formRue.codePostal"
                    name="formRueCodePostal"
                    type="text"
                    class="form-input"
                    :disabled="formRue.disableFields"
                    :class="formRue.disableFields ? 'bg-gray-200' : ''"
                    required
                  />
                </div>
                <div class="flex flex-wrap w-full mt-5 items-center">
                  <label for="formRueVille" class="text-md text mb-2"
                    >Ville</label
                  >
                  <input
                    id="formRueVille"
                    v-model="formRue.ville"
                    name="formRueVille"
                    type="text"
                    class="form-input"
                    :disabled="formRue.disableFields"
                    :class="formRue.disableFields ? 'bg-gray-200' : ''"
                    required
                  />
                </div>
                <div
                  class="
                    w-full
                    mt-7
                    flex flex-wrap
                    justify-center
                    items-center
                    col-span-2
                  "
                >
                  <button
                    class="w-full rounded-full py-3 text-white"
                    :class="
                      formRue.allowSubmit
                        ? 'bg-blue-900'
                        : 'bg-gray-400 cursor-not-allowed'
                    "
                    @click="handleSubmitAdressRue()"
                  >
                    Visualiser l'adresse
                  </button>
                </div>
                <div
                  v-if="
                    Object.keys(formRue.coordonnee).length !== 0 &&
                    formRue.allowToContinue &&
                    formRue.disableFields
                  "
                  class="
                    w-full
                    mt-3
                    flex flex-wrap
                    justify-center
                    items-center
                    col-span-2
                  "
                >
                  <button
                    class="w-full rounded-full py-3 text-white continueButton"
                    @click="handleSubmitRueContinue()"
                  >
                    Générer ma liste
                  </button>
                  <div class="w-full flex items-center mt-2">
                    <svg
                      class="w-5 mr-2"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 496.158 496.158"
                      style="enable-background: new 0 0 496.158 496.158"
                      xml:space="preserve"
                    >
                      <path
                        style="fill: #2e5fff"
                        d="M496.158,248.085c0-137.022-111.069-248.082-248.075-248.082C111.07,0.003,0,111.063,0,248.085
	c0,137.001,111.07,248.07,248.083,248.07C385.089,496.155,496.158,385.086,496.158,248.085z"
                      />
                      <g>
                        <path
                          style="fill: #ffffff"
                          d="M315.249,359.555c-1.387-2.032-4.048-2.755-6.27-1.702c-24.582,11.637-52.482,23.94-57.958,25.015
		c-0.138-0.123-0.357-0.348-0.644-0.737c-0.742-1.005-1.103-2.318-1.103-4.015c0-13.905,10.495-56.205,31.192-125.719
		c17.451-58.406,19.469-70.499,19.469-74.514c0-6.198-2.373-11.435-6.865-15.146c-4.267-3.519-10.229-5.302-17.719-5.302
		c-12.459,0-26.899,4.73-44.146,14.461c-16.713,9.433-35.352,25.41-55.396,47.487c-1.569,1.729-1.733,4.314-0.395,6.228
		c1.34,1.915,3.825,2.644,5.986,1.764c7.037-2.872,42.402-17.359,47.557-20.597c4.221-2.646,7.875-3.989,10.861-3.989
		c0.107,0,0.199,0.004,0.276,0.01c0.036,0.198,0.07,0.5,0.07,0.933c0,3.047-0.627,6.654-1.856,10.703
		c-30.136,97.641-44.785,157.498-44.785,182.994c0,8.998,2.501,16.242,7.432,21.528c5.025,5.393,11.803,8.127,20.146,8.127
		c8.891,0,19.712-3.714,33.08-11.354c12.936-7.392,32.68-23.653,60.363-49.717C316.337,364.326,316.636,361.587,315.249,359.555z"
                        />
                        <path
                          style="fill: #ffffff"
                          d="M314.282,76.672c-4.925-5.041-11.227-7.597-18.729-7.597c-9.34,0-17.475,3.691-24.176,10.971
		c-6.594,7.16-9.938,15.946-9.938,26.113c0,8.033,2.463,14.69,7.32,19.785c4.922,5.172,11.139,7.794,18.476,7.794
		c8.958,0,17.049-3.898,24.047-11.586c6.876-7.553,10.363-16.433,10.363-26.393C321.646,88.105,319.169,81.684,314.282,76.672z"
                        />
                      </g>
                    </svg>
                    <p>Forfait minimum de 100€ par publipostage</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <g-map
          style="min-height: 450px"
          :adresse="gmap.adresse"
          @update-coordonnee="updateCoordonnee"
        />
      </div>
    </div>
    <div v-else class="flex w-full mt-5 justify-center">
      <span class="bg-red-200 p-5">
        <p class="text-red-700">
          Aucun publipostage ne peut être généré à partir de ses informations,
          veuillez réessayer plus tard ou contacter un administrateur
        </p>
      </span>
    </div>
    <BaseModal
      ref="load"
      button-class="flex flex-wrap w-full items-center"
      modal-size="md:max-w-sm"
      :can-close="modalError"
      hide-footer
    >
      <template v-slot:button-content />
      <template v-slot:title />
      <template v-slot:content>
        <div v-if="!modalError" class="flex flex-wrap w-full">
          <div class="flex justify-center w-full">
            <img
              id="generateloader"
              src="@/assets/ico/loader.svg"
              alt="icone de loader"
              class="w-16"
            />
          </div>
          <div class="w-full text-center mt-6">
            <p>La génération d'adresses peut prendre quelques minutes</p>
          </div>
        </div>
        <div v-else class="flex flex-wrap w-full">
          <div class="flex justify-center w-full">
            <img
              src="@/assets/ico/cross.svg"
              alt="icone d'erreur"
              class="w-12"
            />
          </div>
          <div class="w-full text-center mt-6">
            <p>{{ modalErrorMessage }}</p>
          </div>
        </div>
      </template>
    </BaseModal>
  </div>
</template>
<script>
import GMap from "@/components/forms/utils/GMap";
import BaseModal from "@/components/modals/baseModal";
import { participeService } from "@/services";
import ParticipateGenerateModel from "@/model/Publipostage/ParticipateGenerateModel";

export default {
  name: "GeneratePublipostage",
  components: {
    BaseModal,
    GMap,
  },
  data() {
    return {
      generateByChantier: true,
      formChantier: {
        gender: "",
        nom: "",
        prenom: "",
        adresse: "",
        codePostal: "",
        ville: "",
        allowSubmit: false,
        allowToContinue: true,
        disableFields: false,
        coordonnee: {},
      },
      formRue: {
        adresse: "",
        codePostal: "",
        ville: "",
        allowSubmit: false,
        allowToContinue: true,
        disableFields: false,
        coordonnee: {},
      },
      gmap: {
        adresse: null,
      },
      modalError: false,
      modalErrorMessage: "",
    };
  },
  computed: {
    participe() {
      return this.$store.state.participes.currentParticipe;
    },
  },
  watch: {
    formChantier: {
      handler() {
        this.formChantier.allowSubmit =
          this.formChantier.ville.length > 0 &&
          this.formChantier.codePostal.length > 0 &&
          this.formChantier.adresse.length > 0 &&
          this.formChantier.nom.length > 0 &&
          this.formChantier.prenom.length > 0 &&
          this.formChantier.gender.length > 0 &&
          this.formChantier.disableFields === false;
      },
      deep: true,
    },
    formRue: {
      handler() {
        this.formRue.allowSubmit =
          this.formRue.ville.length > 0 &&
          this.formRue.codePostal.length > 0 &&
          this.formRue.adresse.length > 0 &&
          this.formRue.disableFields === false;
      },
      deep: true,
    },
  },
  created() {
    const { dispatch } = this.$store;

    dispatch({
      type: "participes/getParticipe",
      publipostageUuid: this.$route.params.publipostageUuid,
      agenceUuid: this.$route.params.agenceUuid,
    }).then(() => {
      if (this.participe.isDone) {
        if (this.participe.type === "CHANTIER") {
          this.generateByChantier = true;
          this.formChantier.nom = this.participe.nom;
          this.formChantier.prenom = this.participe.prenom;
          this.formChantier.adresse = this.participe.adresse;
          this.formChantier.codePostal = this.participe.codePostal;
          this.formChantier.ville = this.participe.ville;
          this.formChantier.gender = this.participe.gender;
          this.gmap.adresse =
            this.formChantier.adresse +
            " " +
            this.formChantier.codePostal +
            " " +
            this.formChantier.ville;
        }

        if (this.participe.type === "RUE") {
          this.generateByChantier = false;
          this.formRue.adresse = this.participe.adresse;
          this.formRue.codePostal = this.participe.codePostal;
          this.formRue.ville = this.participe.ville;
          this.gmap.adresse =
            this.formRue.adresse +
            " " +
            this.formRue.codePostal +
            " " +
            this.formRue.ville;
        }

        this.formChantier.allowToContinue = false;
        this.formRue.allowToContinue = false;
        this.formChantier.disableFields = true;
        this.formRue.disableFields = true;
      }
    });
  },
  methods: {
    dateFormat(date) {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };

      const objDate = new Date(date);

      return objDate.toLocaleDateString("fr-FR", options);
    },
    changeGender(gender) {
      if (!this.formChantier.disableFields) {
        this.formChantier.gender = gender;
      }
    },
    handleSubmitAdressChantier() {
      if (this.formChantier.allowSubmit) {
        this.gmap.adresse =
          this.formChantier.adresse +
          " " +
          this.formChantier.codePostal +
          " " +
          this.formChantier.ville;
        this.formChantier.disableFields = true;
        this.formChantier.allowSubmit = true;
      }
    },
    handleSubmitAdressRue() {
      if (this.formRue.allowSubmit) {
        this.gmap.adresse =
          this.formRue.adresse +
          " " +
          this.formRue.codePostal +
          " " +
          this.formRue.ville;
        this.formRue.disableFields = true;
        this.formRue.allowSubmit = true;
      }
    },
    handleSubmitChantierContinue() {
      this.$refs.load.toggleModal();

      const participe = new ParticipateGenerateModel(
        "CHANTIER",
        this.formChantier.gender,
        this.formChantier.nom,
        this.formChantier.prenom,
        this.formChantier.adresse,
        this.formChantier.codePostal,
        this.formChantier.ville,
        this.formChantier.coordonnee.lat,
        this.formChantier.coordonnee.long
      );

      this.generateAdresse(participe);
    },
    handleSubmitRueContinue() {
      this.$refs.load.toggleModal();

      const participe = new ParticipateGenerateModel(
        "RUE",
        null,
        null,
        null,
        this.formRue.adresse,
        this.formRue.codePostal,
        this.formRue.ville,
        this.formRue.coordonnee.lat,
        this.formRue.coordonnee.long
      );

      this.generateAdresse(participe);
    },
    generateAdresse(participe) {
      participeService
        .generateParticipe(
          this.$route.params.publipostageUuid,
          this.$route.params.agenceUuid,
          participe
        )
        .then(() => {
          this.$router.push(
            `/publipostages/${this.$route.params.publipostageUuid}/agences/${this.$route.params.agenceUuid}/adresses`
          );
        })
        .catch((error) => {
          if (error.type && error.type === "no_adress_found") {
            this.modalErrorMessage =
              "Nous n'avons pas de résultats pour l'adresse indiquée, veuillez modifier votre recherche afin de pouvoir générer une liste valide.";
          } else {
            this.modalErrorMessage =
              "Une erreurs est survenue, veuillez essayer plus tard ou contacter le service informatique.";
          }

          this.modalError = true;
        });
    },
    editAdressChantier() {
      this.formChantier.allowSubmit = true;
      this.formChantier.disableFields = false;
      this.gmap.adresse = null;
      this.formChantier.allowToContinue = true;
    },
    editAdressRue() {
      this.formRue.allowSubmit = true;
      this.formRue.disableFields = false;
      this.gmap.adresse = null;
      this.formRue.allowToContinue = true;
    },
    updateCoordonnee(event) {
      this.formChantier.coordonnee = event;
      this.formRue.coordonnee = event;
    },
  },
};
</script>

<style lang="scss" scoped>
.continueButton {
  background: transparent linear-gradient(268deg, #f5264c 0%, #e31bb1 100%) 0%
    0% no-repeat padding-box;

  &:hover {
    background: transparent linear-gradient(268deg, #e31bb1 0%, #f5264c 100%) 0%
      0% no-repeat padding-box;
  }
}

button:focus {
  outline: none;
}

#generateloader {
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-direction: reverse;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
