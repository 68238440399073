export default class ParticipateModel {
  constructor(agence, enable) {
    this.agence = agence;
    this.enable = enable;
  }

  getAgence() {
    return this.agence;
  }

  getEnable() {
    return this.enable;
  }
}
