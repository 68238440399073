<template>
  <div />
</template>

<script>
import gmapsInit from "@/helpers/gmaps";

const FRANCE_LAT_LNG = { lat: 46.227638, lng: 2.213749 };
const DEFAULT_ZOOM = 6;

export default {
  name: "GMap",
  props: {
    adresse: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      google: null,
      geocoder: null,
      map: null,
      marker: null,
      circle: null,
    };
  },
  watch: {
    adresse(newAdresse) {
      if (newAdresse) {
        this.search();
      }
      this.setDefault();
    },
  },
  async mounted() {
    try {
      this.google = await gmapsInit();
      this.geocoder = new this.google.maps.Geocoder();
      this.map = new this.google.maps.Map(this.$el, {
        center: FRANCE_LAT_LNG,
        zoom: DEFAULT_ZOOM,
      });

      this.search();
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    search() {
      if (this.geocoder === null || this.adresse === null) {
        return;
      }

      this.geocoder.geocode({ address: this.adresse }, (results, status) => {
        if (status !== "OK" || !results[0]) {
          throw new Error(status);
        }

        const location = results[0].geometry.location;
        this.marker = new this.google.maps.Marker({
          map: this.map,
          position: location,
        });
        this.circle = new this.google.maps.Circle({
          map: this.map,
          strokeWeight: 0,
          fillColor: "#F2235F",
          fillOpacity: 0.18,
          center: location,
          radius: 1000,
        });
        this.map.setCenter(location);
        this.map.setZoom(14);
        this.updateCoordonnee({ lat: location.lat(), long: location.lng() });
      });
    },
    setDefault() {
      this.map.setCenter(FRANCE_LAT_LNG);
      this.map.setZoom(DEFAULT_ZOOM);
      this.marker && this.marker.setMap(null);
      this.circle && this.circle.setMap(null);
      this.updateCoordonnee();
    },
    updateCoordonnee(latLng = {}) {
      this.$emit("update-coordonnee", latLng);
    },
  },
};
</script>
