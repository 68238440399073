import { getDateMonth } from "@/helpers/date";

export default class PublipostageFilter {
  _searchAgences = [];
  _searchStatus = [];
  _searchYear = {};
  _searchMonth = {};

  constructor(agences = [], status = [], year = {}, month = {}) {
    this._searchAgences = agences;
    this._searchStatus = status;
    this._searchYear =
      year.value === undefined
        ? { label: new Date().getFullYear(), value: new Date().getFullYear() }
        : year;
    this._searchMonth =
      month.value === undefined
        ? {
            label: getDateMonth(new Date().getMonth() + 1),
            value: new Date().getMonth() + 1,
          }
        : month;
  }

  getSearchAgences() {
    return this._searchAgences;
  }

  getSearchStatus() {
    return this._searchStatus;
  }

  getSearchYear() {
    return this._searchYear;
  }

  getSearchMonth() {
    return this._searchMonth;
  }

  sanitarizeQueryParam() {
    let str = [];

    if (this.getSearchAgences().length !== 0) {
      this.getSearchAgences().forEach((element) => {
        str.push(
          encodeURIComponent("searchAgences[]") +
            "=" +
            encodeURIComponent(element.value)
        );
      });
    }

    if (this.getSearchStatus().length !== 0) {
      this.getSearchStatus().forEach((element) => {
        str.push(
          encodeURIComponent("searchStatus[]") +
            "=" +
            encodeURIComponent(element.value)
        );
      });
    }

    if (this.getSearchYear() && this.getSearchYear().length !== 0) {
      str.push(
        encodeURIComponent("searchYear") +
          "=" +
          encodeURIComponent(this.getSearchYear().value)
      );
    }

    if (this.getSearchMonth() && this.getSearchMonth().length !== 0) {
      str.push(
        encodeURIComponent("searchMonth") +
          "=" +
          encodeURIComponent(this.getSearchMonth().value)
      );
    }

    return str.join("&");
  }
}
