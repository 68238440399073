import { mixins } from "vue-chartjs";
const { reactiveProp } = mixins;
import "chartjs-plugin-style";

export default {
  mixins: [reactiveProp],
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    this.plugins.forEach((plugin) => {
      this.addPlugin(plugin);
    });
    this.renderChart(this.chartData, this.options);
  },
};
