export default class UsersFilter {
  _searchRoles = [];
  _searchMarques = [];

  sanitarizeQueryParam() {
    let str = [];

    if (this._searchRoles.length > 0) {
      for (const role in this._searchRoles) {
        str.push(
          "searchRoles[]" + "=" + encodeURIComponent(this._searchRoles[role])
        );
      }
    }

    if (this._searchMarques.length > 0) {
      for (const marque in this._searchMarques) {
        str.push(
          "searchMarques[]" +
            "=" +
            encodeURIComponent(this._searchMarques[marque])
        );
      }
    }

    return str.join("&");
  }
}
