<template>
  <div>
    <div class="h-16 flex items-center">
      <h1
        class="text-base lg:text-xl font-semibold mr-auto"
        style="font-family: 'Poppins-Medium', 'sans-serif'"
      >
        Gestion des agences
      </h1>
      <div
        v-if="hasMultipleBrands"
        class="hidden lg:flex mx-auto justify-center items-center"
      >
        <SecondaryNavItem
          v-for="marque in marques"
          :key="marque.uuid"
          :link="marque.code"
          :current-value="value"
          no-router
          v-on="$listeners"
        >
          {{ marque.nom }}
        </SecondaryNavItem>
      </div>
      <BaseModal
        v-if="isAdmin"
        ref="addAgenceModal"
        class="ml-auto mr-4"
        modal-size="md:max-w-3xl"
        @validate="callValidate"
      >
        <template v-slot:button-content>
          <img
            src="@/assets/ico/plus.svg"
            alt="Ajouter un publipostage."
            class="w-7 max-w-lg"
          />
          <p class="ml-4">Créer une agence</p>
        </template>
        <template v-slot:title>
          <h3
            class="text-lg lg:text-xl"
            style="font-family: 'Poppins-SemiBold', 'sans-serif'"
          >
            Création d'une agence
          </h3>
        </template>
        <template v-slot:content>
          <AgenceForm ref="addAgence" @closeModal="closeModal" />
        </template>
      </BaseModal>
      <div v-else class="ml-auto w-56" />
      <slot name="header-buttons" />
    </div>
    <div
      v-if="hasMultipleBrands"
      class="flex lg:hidden justify-center items-center my-11"
    >
      <SecondaryNavItem
        v-for="marque in marques"
        :key="marque.uuid"
        :link="marque.code"
        :current-value="value"
        no-router
        v-on="$listeners"
      >
        {{ marque.nom }}
      </SecondaryNavItem>
    </div>
  </div>
</template>

<script>
import SecondaryNavItem from "@/components/utils/menu/SecondaryNavItem";
import BaseModal from "@/components/modals/baseModal";
import AgenceForm from "@/components/forms/agence/AgenceForm";
import { isAdmin } from "@/helpers/user";
import { mapState } from "vuex";

export default {
  name: "AgenceMenu",
  components: {
    SecondaryNavItem,
    BaseModal,
    AgenceForm,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    hasMultipleBrands: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    isAdmin() {
      return isAdmin();
    },
    ...mapState({
      marques: (state) => state.marques.marques,
    }),
  },
  methods: {
    callValidate() {
      this.$refs.addAgence.handleCreateSubmit();
    },
    closeModal() {
      this.$refs.addAgenceModal.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped></style>
