import { authHeader, fetchResponse, jsonStringify } from "@/helpers";
import config from "@/config";

export const venteDeclareService = {
  update,
  remove,
};

function update(id, data) {
  const options = {
    method: "PUT",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
    body: jsonStringify(data),
  };

  return fetchResponse(`${config.apiUrl}/api/vente-declare/${id}`, options);
}

function remove(id) {
  const options = {
    method: "DELETE",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
  };

  return fetchResponse(`${config.apiUrl}/api/vente-declare/${id}`, options);
}
