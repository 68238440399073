<script>
import { Line } from "vue-chartjs";
import baseChartMixin from "@/components/reporting/indicators/baseChartMixin";

export default {
  name: "LineChart",
  extends: Line,
  mixins: [baseChartMixin],
};
</script>
