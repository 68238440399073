import config from "@/config";
import { authHeader, fetchResponse, getDefaultOption } from "@/helpers";

export const reportingService = {
  getAmountByPublipostageData,
  getPublipostagesPercentage,
  getCaTotal,
};

function getAmountByPublipostageData(filters) {
  const url = formatUrl(
    `${config.apiUrl}/api/reporting/ca-by-publipostage`,
    filters
  );
  return fetchResponse(url, getDefaultOption("GET", authHeader()));
}

function getPublipostagesPercentage(filters) {
  const url = formatUrl(
    `${config.apiUrl}/api/reporting/publipostages-percentage`,
    filters
  );
  return fetchResponse(url, getDefaultOption("GET", authHeader()));
}

function getCaTotal(filters) {
  const url = formatUrl(`${config.apiUrl}/api/reporting/ca-total`, filters);
  return fetchResponse(url, getDefaultOption("GET", authHeader()));
}

function formatUrl(url, params) {
  const urlObject = new URL(url);
  for (const paramName in params) {
    urlObject.searchParams.append(paramName, params[paramName]);
  }
  return urlObject;
}
