<template>
  <div class="flex flex-wrap w-full p-7 px-12">
    <h1 class="text-2xl w-full text-secondary">Générer un publipostage</h1>
    <div
      v-if="isActif"
      class="w-full flex flex-wrap items-end justify-between mt-6"
    >
      <router-link
        :to="{
          name: 'GeneratePublipostage',
          params: {
            publipostageUuid: this.$route.params.publipostageUuid,
            agenceUuid: this.$route.params.agenceUuid,
          },
        }"
      >
        <button
          class="
            mt-5
            p-4
            border border-gray-200
            rounded-2xl
            shadow
            flex
            items-center
            text-secondary
          "
        >
          <img
            src="@/assets/ico/retour-arrow.svg"
            alt="Retour en arrière"
            class="h-3 w-auto mr-2"
          />
          Modifier l'adresse
        </button>
      </router-link>
      <div>
        <p v-if="participe.agence" class="ml-5 text-primary">
          Cette liste contient {{ adresses.length }} adresses. Seulement les
          {{ participe.agence.nbResultatsMax }} premières non-exclu seront
          utilisées.
        </p>
      </div>
    </div>
    <div
      v-if="adresses"
      class="flex flex-wrap mt-5 w-full p-5 border border-gray-200 rounded-2xl"
    >
      <div class="flex flex-wrap w-full justify-between items-center">
        <div class="flex flex-wrap">
          <p class="text-secondary">
            <span v-show="participe.prenom && participe.nom"
              >{{ participe.prenom }} {{ participe.nom }} -</span
            >
            {{ participe.adresse }} - {{ participe.codePostal }}
            {{ participe.ville }}
          </p>
        </div>
        <div class="flex items-center mb-4 text-primary">
          <p class="mr-4">
            {{ nbAdresseSelectionnee }}
          </p>
          <BaseModal
            v-if="isActif"
            ref="buttonRetirer"
            button-class="border border-gray-200 shadow-md p-3 rounded-lg mr-2"
            modal-size="md:max-w-md"
            :active="adressesSelected.length > 0"
            @validate="activateAdresse(false)"
          >
            <template v-slot:button-content>
              <img
                src="@/assets/ico/blacklist.svg"
                alt="Supprimer adresse"
                class="h-6 w-6"
              />
            </template>
            <template v-slot:title>
              <div class="flex flex-wrap items-center justify-center w-full">
                <h2 class="text-xl text-black">Retirer</h2>
              </div>
            </template>
            <template v-slot:content>
              <div class="flex flex-wrap items-center justify-center w-full">
                <div class="text-center">
                  Vous avez {{ nbAdresseSelectionnee }}. <br />
                  Êtes-vous sûr de vouloir
                  {{ adressesSelected.length > 1 ? "les" : "la" }} blacklister ?
                </div>
              </div>
            </template>
            <template v-slot:validation-content> Retirer </template>
          </BaseModal>
          <BaseModal
            v-if="isActif"
            ref="buttonIntegrer"
            button-class="border border-gray-200 shadow-md p-3 rounded-lg mr-2 text-secondary"
            modal-size="md:max-w-md"
            :active="adressesSelected.length > 0"
            @validate="activateAdresse()"
          >
            <template v-slot:button-content>
              <div class="h-6 w-6 flex items-center justify-center">
                <font-awesome-icon icon="plus-circle" size="2x" />
              </div>
            </template>
            <template v-slot:title>
              <div class="flex flex-wrap items-center justify-center w-full">
                <h2 class="text-xl text-black">Intégrer</h2>
              </div>
            </template>
            <template v-slot:content>
              <div class="flex flex-wrap items-center justify-center w-full">
                <div class="text-center">
                  Vous avez {{ nbAdresseSelectionnee }}. <br />
                  Êtes-vous sûr de vouloir
                  {{ adressesSelected.length > 1 ? "les " : "l'" }}intégrer ?
                </div>
              </div>
            </template>
            <template v-slot:validation-content> Intégrer </template>
          </BaseModal>
        </div>
      </div>
      <div class="flex flex-wrap w-full mt-5">
        <table class="w-full">
          <thead>
            <tr>
              <td />
              <td class="flex justify-center">
                <div
                  class="
                    flex
                    justify-center
                    items-center
                    p-2
                    border border-gray-200
                    rounded-lg
                    mb-5
                  "
                >
                  <div
                    class="
                      p-1
                      w-5
                      h-5
                      border border-gray-200
                      rounded-md
                      cursor-pointer
                    "
                    @click="toggleAllSwitch()"
                  >
                    <div
                      class="w-3 h-3 w-full h-full rounded-sm"
                      :class="
                        adressesSelected.length === adresses.length
                          ? 'bg-primary'
                          : ''
                      "
                    />
                  </div>
                </div>
              </td>
              <td>Nom</td>
              <td>Téléphone</td>
              <td>Adresse</td>
              <td>Code Postal</td>
              <td>Ville</td>
              <td>Distance</td>
            </tr>
          </thead>
          <tbody class="mt-5">
            <tr
              v-for="(adresse, index) in adresses"
              :key="adresse.uuid"
              class="w-full border-t border-gray-200 h-10 min-h-full"
              :class="buildRowClasses(index)"
              @click="toggleSwitch(adresse.uuid)"
            >
              <td :class="getClass(adresse)">{{ index + 1 }}.</td>
              <td>
                <div class="flex justify-center items-center p-3">
                  <div
                    class="
                      p-1
                      w-5
                      h-5
                      border border-gray-200
                      rounded-md
                      cursor-pointer
                    "
                  >
                    <div
                      class="w-3 h-3 w-full h-full rounded-sm"
                      :class="
                        adressesSelected.includes(adresse.uuid)
                          ? 'bg-primary'
                          : ''
                      "
                    />
                  </div>
                </div>
              </td>
              <td :class="getClass(adresse)" class="lowercase">
                {{ adresse.adresse.nom }}
              </td>
              <td :class="getClass(adresse)">
                {{ adresse.adresse.telephone }}
              </td>
              <td :class="getClass(adresse)" class="lowercase">
                {{ adresse.adresse.rue }}
              </td>
              <td :class="getClass(adresse)">
                {{ adresse.adresse.codePostal }}
              </td>
              <td :class="getClass(adresse)" class="lowercase">
                {{ adresse.adresse.ville }}
              </td>
              <td :class="getClass(adresse)">
                {{ displayDistance(adresse.km) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import BaseModal from "@/components/modals/baseModal";
import PatchAdresseModel from "@/model/Publipostage/Participe/PatchAdresseModel";

export default {
  name: "ListAdresses",
  components: {
    BaseModal,
  },
  data() {
    return {
      adressesSelected: [],
    };
  },
  computed: {
    adresses() {
      if (
        !this.isActif &&
        this.$store.state.participes.currentAdresses &&
        Array.isArray(this.$store.state.participes.currentAdresses) &&
        this.nbResultatsMax
      ) {
        return this.$store.state.participes.currentAdresses
          .filter((adresse) => adresse.use)
          .slice(0, this.nbResultatsMax);
      }

      return this.$store.state.participes.currentAdresses;
    },
    nbAdresseSelectionnee() {
      if (this.adressesSelected.length === 1) {
        return "1 adresse séléctionnée";
      } else if (this.adressesSelected.length > 0) {
        return this.adressesSelected.length + " adresses séléctionnées";
      }

      return "";
    },
    participe() {
      return this.$store.state.participes.currentParticipe;
    },
    isEnvoye() {
      if (this.participe && this.participe.publipostage) {
        return this.participe.publipostage.status === "ENVOYE";
      }

      return false;
    },
    isActif() {
      if (this.participe && this.participe.publipostage) {
        return this.participe.publipostage.status === "ACTIF";
      }

      return false;
    },
    nbResultatsMax() {
      return this.participe.agence?.nbResultatsMax;
    },
    maxIndex() {
      let index = 0;
      let nbUsedAdresse = 0;
      for (const adresse of this.adresses) {
        index++;
        if (adresse.use) nbUsedAdresse++;
        if (nbUsedAdresse === this.nbResultatsMax) break;
      }
      return index;
    },
  },
  async created() {
    const { dispatch } = this.$store;

    await dispatch({
      type: "participes/getAdresses",
      publipostageUuid: this.$route.params.publipostageUuid,
      agenceUuid: this.$route.params.agenceUuid,
    });

    await dispatch({
      type: "participes/getParticipe",
      publipostageUuid: this.$route.params.publipostageUuid,
      agenceUuid: this.$route.params.agenceUuid,
    });
  },
  methods: {
    toggleSwitch(adresseUuid) {
      if (this.isActif) {
        if (this.adressesSelected.includes(adresseUuid)) {
          this.adressesSelected.splice(
            this.adressesSelected.indexOf(adresseUuid),
            1
          );
        } else {
          this.adressesSelected.push(adresseUuid);
        }
      }
    },
    toggleAllSwitch() {
      if (this.adressesSelected.length !== this.adresses.length) {
        this.adressesSelected = [];
        this.adresses.map((adresse) => {
          this.adressesSelected.push(adresse.uuid);
        });
      } else {
        this.adressesSelected = [];
      }
    },
    displayDistance(distance) {
      const distanceMeter = distance * 1000;
      return distanceMeter.toFixed(0) + " m";
    },
    activateAdresse(activate = true) {
      const { dispatch } = this.$store;
      const patchAdresses = this.adressesSelected.map(
        (adresse) => new PatchAdresseModel(adresse, activate)
      );

      dispatch({
        type: "participes/patchAdresses",
        publipostageUuid: this.$route.params.publipostageUuid,
        agenceUuid: this.$route.params.agenceUuid,
        patchAdressesDto: patchAdresses,
      });

      this.$refs.buttonRetirer.closeModal();
      this.$refs.buttonIntegrer.closeModal();
      this.adressesSelected = [];
    },
    getClass(adresse) {
      return adresse.use ? "" : "opacity-50 line-through";
    },
    buildRowClasses(index) {
      const classes = [];
      if (this.isActif) classes.push("cursor-pointer");
      if (index + 1 > this.maxIndex) {
        classes.push("bg-gray-100");
      }
      return classes;
    },
  },
};
</script>

<style lang="scss" scoped>
#saveButton {
  background: transparent linear-gradient(268deg, #f5264c 0%, #e31bb1 100%) 0%
    0% no-repeat padding-box;

  &:hover {
    background: transparent linear-gradient(268deg, #e31bb1 0%, #f5264c 100%) 0%
      0% no-repeat padding-box;
  }
}
</style>
