<template>
  <BaseModal
    button-class="flex flex-wrap w-full items-center"
    modal-size="md:max-w-8xl"
    hide-footer
  >
    <template v-slot:button-content>
      <div class="cursor-pointer flex relative">
        <div style="z-index: 1">
          <img
            src="@/assets/ico/liste-agences.svg"
            alt="icone des paramètres"
            class="w-8"
          />
        </div>
        <div class="-ml-4" style="z-index: 2">
          <img
            src="@/assets/ico/liste-agences.svg"
            alt="icone des paramètres"
            class="w-8"
          />
        </div>
        <div class="-ml-4" style="z-index: 3">
          <img
            src="@/assets/ico/liste-agences.svg"
            alt="icone des paramètres"
            class="w-8"
          />
        </div>
        <div class="-ml-4" style="z-index: 4">
          <img
            src="@/assets/ico/liste-agences.svg"
            alt="icone des paramètres"
            class="w-8"
          />
        </div>
      </div>
      <p class="pl-2 text-sm cursor-pointer">Voir la liste des agences</p>
    </template>
    <template v-slot:title>
      <div class="flex flex-wrap text-center">
        <h3 class="text-2xl font-semibold w-full">
          {{ publipostage.nom }}
        </h3>
        <h4 class="w-full text-sm mt-2">
          <img
            src="@/assets/ico/date.svg"
            alt="icone date"
            class="w-5 mr-2 inline-block"
          />
          {{ dateFormat(publipostage.dateMiseEnLigne) }} -
          {{ dateFormat(publipostage.dateLimite) }}
        </h4>
      </div>
    </template>
    <template v-slot:content>
      <div class="grid grid-cols-1 lg:grid-cols-3 gap-4">
        <div class="border border-gray-200 rounded-2xl p-5">
          <div
            class="
              flex
              items-center
              justify-center
              pb-6
              border-b border-gray-200
            "
          >
            <div
              class="
                flex
                items-center
                justify-center
                rounded-full
                w-8
                h-8
                bg-green-100
                text-green-500
                flex-shrink-0
              "
            >
              <font-awesome-icon icon="check" />
            </div>
            <h5 class="ml-5">
              {{ getTitleFor("agence-genere", agencesGenere) }}
            </h5>
          </div>
          <select-agence
            :agences="agencesGenere"
            :vente-declare="true"
            :publipostage="publipostage"
          />
        </div>
        <div class="border border-gray-200 rounded-2xl p-5">
          <div
            class="
              flex
              items-center
              justify-center
              pb-6
              border-b border-gray-200
            "
          >
            <div
              class="
                flex
                items-center
                justify-center
                rounded-full
                w-8
                h-8
                bg-red-100
                text-red-500
                flex-shrink-0
              "
            >
              <font-awesome-icon icon="times" />
            </div>
            <h5 class="ml-5">
              {{ getTitleFor("agence-non-genere", agencesNonGenere) }}
            </h5>
          </div>
          <select-agence
            :agences="agencesNonGenere"
            :agences-selected="agencesNonGenereSelected"
            :must-be-selected="true"
            button-text="Relancer"
            @update:agencesSelected="agencesNonGenereSelected = $event"
          >
            <template v-slot:action-content>
              <div class="flex items-center">
                <button
                  class="
                    py-2
                    px-4
                    h-10
                    bg-secondary
                    text-white text-sm
                    rounded-l-lg
                    border-r border-white
                  "
                  @click="relancerAgence"
                >
                  Relancer
                </button>
                <div class="relative">
                  <button
                    ref="buttonRelancer"
                    v-observe-visibility="buttonRelancerVisibilityChanged"
                    class="
                      relative
                      z-10
                      block
                      bg-secondary
                      text-white
                      rounded-r-lg
                      p-2
                      w-10
                      h-10
                    "
                    @click="dropdownNonGenereOpen = !dropdownNonGenereOpen"
                  >
                    <font-awesome-icon icon="chevron-down" size="1x" />
                  </button>
                  <div
                    v-if="dropdownNonGenereOpen"
                    ref="dropdownRelancer"
                    v-click-outside="handleClickOutsideDropdown"
                    class="
                      w-48
                      bg-white
                      rounded-md
                      overflow-hidden
                      shadow-xl
                      border border-gray-200
                      z-20
                    "
                  >
                    <span
                      class="
                        block
                        px-4
                        py-2
                        text-sm text-gray-800
                        border-b
                        hover:bg-gray-200
                        cursor-pointer
                      "
                      @click="deactivateAgence"
                      >Retirer</span
                    >
                  </div>
                </div>
              </div>
            </template>
          </select-agence>
        </div>
        <div class="border border-gray-200 rounded-2xl p-5">
          <div
            class="
              flex
              items-center
              justify-center
              pb-6
              border-b border-gray-200
            "
          >
            <div
              class="
                flex
                items-center
                justify-center
                rounded-full
                w-8
                h-8
                bg-yellow-100
                text-yellow-500
                flex-shrink-0
              "
            >
              <font-awesome-icon icon="exclamation" />
            </div>
            <h5 class="ml-5">
              {{ getTitleFor("agence-exclu", agencesExclu) }}
            </h5>
          </div>
          <select-agence
            :agences="agencesExclu"
            :agences-selected="agencesExcluSelected"
            :must-be-selected="true"
            button-text="Ajouter"
            @update:agencesSelected="agencesExcluSelected = $event"
          >
            <template v-slot:action-content>
              <button
                class="bg-secondary text-white rounded-lg px-5 py-3 text-sm"
                @click="activateAgence()"
              >
                Ajouter
              </button>
            </template>
          </select-agence>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import selectAgence from "@/components/utils/Publipostage/selectAgence";
import ParticipateModel from "@/model/Publipostage/ParticipateModel";
import RelanceAgenceModel from "@/model/Publipostage/Agence/RelanceAgenceModel";
import BaseModal from "@/components/modals/baseModal";
import { createPopper } from "@popperjs/core";
import { ObserveVisibility } from "vue-observe-visibility";
import ClickOutside from "vue-click-outside";

export default {
  name: "PublipostageListAgences",
  components: {
    BaseModal,
    selectAgence,
  },
  directives: {
    ObserveVisibility,
    ClickOutside,
  },
  props: {
    publipostage: {
      type: Object,
      required: true,
    },
    publipostageFilter: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    agencesGenere: [],
    agencesNonGenere: [],
    agencesNonGenereSelected: [],
    agencesExclu: [],
    agencesExcluSelected: [],
    dropdownNonGenereOpen: false,
    relancerDropdownPopper: null,
    clickOutsideDropown: 0,
  }),
  watch: {
    publipostage: {
      handler() {
        this.dispatch();
      },
    },
    dropdownNonGenereOpen: {
      handler() {
        if (this.dropdownNonGenereOpen) {
          this.$nextTick(() => {
            this.relancerDropdownPopper = createPopper(
              this.$refs.buttonRelancer,
              this.$refs.dropdownRelancer,
              {
                placement: "bottom-end",
                strategy: "fixed",
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, 8],
                    },
                  },
                ],
              }
            );
          });
        } else {
          this.clickOutsideDropown = 0;
          this.relancerDropdownPopper && this.relancerDropdownPopper.destroy();
        }
      },
    },
  },
  created() {
    this.dispatch();
  },
  methods: {
    dateFormat(date) {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };

      const objDate = new Date(date);

      return objDate.toLocaleDateString("fr-FR", options);
    },
    getSizeOf(object) {
      return object.length;
    },
    getTitleFor(type, agences) {
      switch (type) {
        case "agence-exclu":
          if (this.getSizeOf(agences) === 1) {
            return "1 agence n'est pas associé à ce publipostage";
          } else if (this.getSizeOf(agences) === 0) {
            return "Aucune agence non associé à ce publipostage";
          } else {
            return (
              this.getSizeOf(agences) +
              " agences ne sont pas associées à ce publipostage"
            );
          }
        case "agence-non-genere":
          if (this.getSizeOf(agences) === 1) {
            return "1 agence n'a pas généré ce publipostage";
          } else if (this.getSizeOf(agences) === 0) {
            return "Toutes les agences ont généré ce publipostage";
          } else {
            return (
              this.getSizeOf(agences) +
              " agences n'ont pas généré ce publipostage"
            );
          }
        case "agence-genere":
          if (this.getSizeOf(agences) === 1) {
            return "1 agence a généré ce publipostage";
          } else if (this.getSizeOf(agences) === 0) {
            return "Aucune agence n'à généré ce publipostage";
          } else {
            return (
              this.getSizeOf(agences) + " agences ont généré ce publipostage"
            );
          }
        default:
          return "unknown type";
      }
    },
    deactivateAgence() {
      const { dispatch } = this.$store;
      const participates = this.agencesNonGenereSelected.map(
        (participate) => new ParticipateModel(participate, false)
      );

      if (this.publipostage.uuid) {
        dispatch("publipostages/updateParticipate", {
          publipostageUuid: this.publipostage.uuid,
          participates: participates,
          publipostageFilter: this.publipostageFilter,
        });
        this.agencesNonGenereSelected = [];
      }
    },
    activateAgence() {
      const { dispatch } = this.$store;

      const participates = this.agencesExcluSelected.map(
        (participate) => new ParticipateModel(participate, true)
      );

      if (this.publipostage.uuid) {
        dispatch("publipostages/updateParticipate", {
          publipostageUuid: this.publipostage.uuid,
          participates: participates,
          publipostageFilter: this.publipostageFilter,
        });
        this.agencesExcluSelected = [];
      }
    },
    relancerAgence() {
      const { dispatch } = this.$store;
      const agences = this.agencesNonGenereSelected.map(
        (agenceUuid) => new RelanceAgenceModel(agenceUuid)
      );

      if (this.publipostage.uuid) {
        dispatch("publipostages/relanceAgence", {
          publipostageUuid: this.publipostage.uuid,
          agences: agences,
          publipostageFilter: this.publipostageFilter,
        });
        this.agencesNonGenereSelected = [];
      }
    },
    dispatch() {
      this.agencesGenere = this.publipostage.participes.filter(
        (participe) => participe.participe === true && participe.isDone === true
      );
      this.agencesNonGenere = this.publipostage.participes.filter(
        (participe) =>
          participe.participe === true && participe.isDone === false
      );
      this.agencesExclu = this.publipostage.participes.filter(
        (participe) => participe.participe === false
      );
    },
    buttonRelancerVisibilityChanged(isVisible) {
      if (isVisible) {
        return;
      }
      this.dropdownNonGenereOpen = false;
    },
    handleClickOutsideDropdown() {
      this.clickOutsideDropown++;
      if (this.clickOutsideDropown === 2) {
        this.dropdownNonGenereOpen = false;
      }
    },
  },
};
</script>
