export function authHeader() {
  // return authorization header with jwt token
  let user = JSON.parse(localStorage.getItem("user"));

  if (user && user.token) {
    return { Authorization: "Bearer " + user.token };
  }

  return {};
}

export function refreshTokenBody() {
  const user = JSON.parse(localStorage.getItem("user"));

  if (user && user.refreshToken) {
    return { refresh_token: user.refreshToken };
  }

  return {};
}
