import { marqueService } from "@/services";

export const marques = {
  namespaced: true,
  state: {
    marques: [],
    loaded: false,
  },
  actions: {
    async getMarques({ state, commit }) {
      if (!state.loaded) {
        await marqueService.getMarques().then(async (marques) => {
          await commit("setMarques", marques);
          commit("setLoaded", true);
        });
      }

      return state.marques;
    },
  },
  mutations: {
    setMarques(state, marques) {
      state.marques = marques.results;
    },
    setLoaded(state, loaded) {
      state.loaded = loaded;
    },
  },
};
