import { agenceService } from "@/services";
import AgenceFilter from "@/model/AgenceFilter";

export const agences = {
  namespaced: true,
  state: {
    agences: [],
  },
  actions: {
    async getAgences({ commit, dispatch }, { agenceFilter }) {
      await dispatch({ type: "marques/getMarques" }, { root: true });

      let pagination = 1;
      let arrayAgences = [];
      let total = 0;

      do {
        await agenceService
          .getAgences(agenceFilter, pagination)
          .then((agences) => {
            total = agences.pages;
            pagination++;
            arrayAgences.push(...agences.results);
          });
      } while (total >= pagination);

      commit("setAgences", { agences: arrayAgences });
      dispatch("loading/stopLoad", "", { root: true });
    },
    async createAgence({ dispatch }, { agence }) {
      dispatch("loading/startLoad", "", { root: true });

      await agenceService
        .createAgence(agence)
        .then()
        .finally(() => {
          dispatch({
            type: "getAgences",
            agenceFilter: new AgenceFilter(),
          });
        });
    },
    async updateAgence({ dispatch }, { agence, agenceUuid, agenceFilter }) {
      dispatch("loading/startLoad", "", { root: true });
      await agenceService.updateAgence(agence, agenceUuid);
      dispatch({ type: "getAgences", agenceFilter: agenceFilter });
    },
    deleteAgence({ dispatch, commit }, { agenceUuid }) {
      dispatch("loading/startLoad", "", { root: true });
      agenceService
        .deleteAgence(agenceUuid)
        .then(() => {
          commit("removeAgence", { agenceUuid });
        })
        .finally(() => {
          dispatch("loading/stopLoad", "", { root: true });
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
  },
  mutations: {
    setAgences(state, { agences }) {
      state.agences = agences;
    },
    removeAgence(state, { agenceUuid }) {
      state.agences = state.agences.filter(
        (agence) => agence.uuid !== agenceUuid
      );
    },
  },
};
