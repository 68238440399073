import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/css/tailwind.css";
import { VueSpinners } from "@saeris/vue-spinners";

import Multiselect from "vue-multiselect";
import "./assets/css/multiselect.css";

import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronRight,
  faBars,
  faPlusCircle,
  faTimesCircle,
  faSearch,
  faExclamationTriangle,
  faUser,
  faPhone,
  faMapMarkerAlt,
  faPen,
  faTrash,
  faEllipsisH,
  faCheck,
  faTimes,
  faExclamation,
  faChevronDown,
  faEdit,
  faEnvelope,
  faCommentDollar,
  faThList,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faChevronRight,
  faBars,
  faPlusCircle,
  faTimesCircle,
  faSearch,
  faExclamationTriangle,
  faUser,
  faPhone,
  faMapMarkerAlt,
  faPen,
  faTrash,
  faEllipsisH,
  faCheck,
  faTimes,
  faExclamation,
  faChevronDown,
  faEdit,
  faEnvelope,
  faCommentDollar,
  faThList
);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("multiselect", Multiselect);
Vue.component("datetime", Datetime);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
  VueSpinners,
}).$mount("#app");
