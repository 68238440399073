export default class AgenceFilter {
  _searchKeyword = null;

  constructor(keyword = null) {
    this._searchKeyword = keyword;
  }

  getSearchKeyword() {
    return this._searchKeyword;
  }

  sanitarizeQueryParam() {
    let str = [];

    if (null !== this.getSearchKeyword() && "" !== this.getSearchKeyword()) {
      str.push(
        encodeURIComponent("searchKeyword") +
          "=" +
          encodeURIComponent(this.getSearchKeyword())
      );
    }

    return str.join("&");
  }
}
