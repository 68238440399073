export default class PatchAdresseVenteDeclareModel {
  constructor(adresse, venteDeclare) {
    this.adresse = adresse;
    this.venteDeclare = venteDeclare;
  }

  getAdresse() {
    return this.adresse;
  }

  getVenteDeclare() {
    return this.venteDeclare;
  }
}
