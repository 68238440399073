import Vue from "vue";
import Vuex from "vuex";
import { alert } from "./alert.module";
import { loading } from "./loading.module";
import { authentication } from "./authentication.module";
import { users } from "./users.module";
import { agences } from "./agences.module";
import { marques } from "./marques.module";
import { publipostages } from "./publipostages.module";
import { participes } from "./participes.module";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    alert,
    authentication,
    users,
    loading,
    agences,
    marques,
    publipostages,
    participes,
  },
});
