import store from "@/store";
import { getDateMonth } from "@/helpers/date";

export default class PublipostageFilter {
  _searchMarques = [];
  _searchStatus = [];
  _searchYear = {};
  _searchMonth = {};

  constructor(marques = [], status = [], year = {}, month = {}) {
    this._searchMarques = marques;
    this._searchStatus = status;
    this._searchYear =
      year.value === undefined
        ? { label: new Date().getFullYear(), value: new Date().getFullYear() }
        : year;
    this._searchMonth =
      month.value === undefined
        ? {
            label: getDateMonth(new Date().getMonth() + 1),
            value: new Date().getMonth() + 1,
          }
        : month;
  }

  getSearchMarques() {
    return this._searchMarques;
  }

  getSearchStatus() {
    return this._searchStatus;
  }

  getSearchYear() {
    return this._searchYear;
  }

  getSearchMonth() {
    return this._searchMonth;
  }

  sanitarizeQueryParam() {
    let str = [];
    if (this.getSearchMarques().length !== 0) {
      this.getSearchMarques().forEach(async (element) => {
        const marque = store.state.marques.marques.filter((marque) => {
          return marque.code === element.value;
        })[0];

        str.push(
          encodeURIComponent("searchMarques[]") +
            "=" +
            encodeURIComponent(marque.uuid)
        );
      });
    }

    if (this.getSearchStatus().length !== 0) {
      this.getSearchStatus().forEach((element) => {
        str.push(
          encodeURIComponent("searchStatus[]") +
            "=" +
            encodeURIComponent(element.value)
        );
      });
    }

    if (this.getSearchYear() && this.getSearchYear().length !== 0) {
      str.push(
        encodeURIComponent("searchYear") +
          "=" +
          encodeURIComponent(this.getSearchYear().value)
      );
    }

    if (this.getSearchMonth() && this.getSearchMonth().length !== 0) {
      str.push(
        encodeURIComponent("searchMonth") +
          "=" +
          encodeURIComponent(this.getSearchMonth().value)
      );
    }

    return str.join("&");
  }
}
